import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import getListsIntersectionValues from "lodash.intersection";
import { getSpendActivities } from "../../../redux/actions/activities";
import { getLocation } from "../../../redux/actions/location";
import * as S from "./style";
import * as T from "../../../components/Typograpy";
import Divider from "../../../components/Divider";

import { GENERAL } from "../../../constants/navRoutes";
import {
  spendActivitiesLocationTypesKeys,
  spendActivitiesDropDownEnglishSorted as spendActivityTypesEnglish,
  spendActivitiesDropDownWelsh,
} from "../../../constants/dropdDownData";

import EarnSpendOpportunity from "../../../components/Cards/EarnSpendOpportunity";
import Loading from "../../../components/Loading";
import { Row, Col } from "../../../components/Grid";
import { TabsSmall } from "../../../components/Tabs";
import {
  Dropdown,
  InputField,
  Checkbox,
  AutoComplete,
} from "../../../components/Inputs";
import Button from "../../../components/Button";

import sortByLocation from "../../../utils/helpers/sortByLocation";
import t from "../../../utils/helpers/translator";

const topTabs = ["nearby", "online"];
const sortByTabs = ["popularity", "recentlyAdded", "nearest", "relevance"];
const initialState = {
  postcode: "",
  location: "",
  county: "",
  keyword: "",
  selectedActivity: "",
  page: 1,
  tab: topTabs[0],
  sortBy: sortByTabs[0],
  distance: 0,
  includeNationalOffers: true,
  availableOffersOnly: false,
  tracker: 1, // this is to get new results when clearing filters
};

const generateQuery = (obj, searchParams) => {
  Object.keys(obj).map((key) => searchParams.set(key, obj[key]));
  const result = searchParams.toString();
  return result;
};

const getQuery = (searchParams) => {
  const currentState = { ...initialState };
  Object.keys(initialState).forEach((key) => {
    if (searchParams.get(key)) {
      const currentValue = searchParams.get(key);
      currentState[key] = currentValue;
    }
  });
  return currentState;
};

const Spend = ({
  getSpendActivitiesAction,
  getSpendActivitiesLoading,
  spendActivities = [],
  lastFeaturedActivities = [],
  getLocationAction,
  language,
  citiesList,
  countiesList,
  homonymousStatesList,
  initialState: initialStateWithSearchPrams,
  searchParams,
  userPostcode,
}) => {
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [searchData, setSearchData] = useState(initialStateWithSearchPrams);
  const [availableActivitiesTypesArr, setAvailableActivitiesTypesArr] =
    useState([]);
  const firstUpdate = useRef(true);
  const cardsSectionRef = useRef();

  const handleTab = (tabText) => {
    setSearchData((_data) => ({
      ..._data,
      tab: tabText,
      page: 1,
      sortBy: "popularity",
    }));
  };
  const handleSortTab = (tabText) => {
    let postcode = searchData.postcode;
    let distance = searchData.distance;
    if (tabText === "nearest" && !postcode) {
      postcode = userPostcode;
      distance = 50;
    }
    setSearchData((_data) => ({
      ..._data,
      postcode,
      distance,
      sortBy: tabText,
      page: 1,
    }));
  };

  const handleChange = (key, value) => {
    setSearchData((_data) => {
      let newSearchData = {
        ..._data,
        [key]: value,
      };

      if (key === "keyword" && _data.sortBy === "relevance" && !value) {
        newSearchData = { ...newSearchData, sortBy: "popularity", page: 1 };
      }

      if (key === "postcode") {
        newSearchData = {
          ...newSearchData,
          location: value ? "" : searchData.location,
          county: value ? "" : searchData.county,
          distance: value ? 50 : 0,
        };
      }

      // if no location then reset sortBy to popularity and page to 1 if sortBy is nearest
      if (
        key === "postcode" &&
        !newSearchData.location &&
        !newSearchData.postcode &&
        !newSearchData?.county &&
        newSearchData.sortBy === "nearest"
      ) {
        newSearchData = {
          ...newSearchData,
          sortBy: "popularity",
          page: 1,
        };
      }

      return newSearchData;
    });
  };

  const countyFilter = (activities, countyName) => {
    const filtered = activities.filter((activity) =>
      activity.county?.includes(countyName || searchData.county),
    );
    return filtered;
  };

  const cityFilter = (activities) => {
    const filtered = activities.filter((activity) =>
      activity.city?.includes(searchData.location),
    );
    return filtered;
  };

  const locationFilter = async (activities) => {
    const locationSearchKeyword = searchData.location || searchData.county;
    if (
      searchData.tab === "online" ||
      (!searchData.postcode && !locationSearchKeyword)
    ) {
      return activities;
    }

    let data = [];
    let filteredCountries = [];

    // const postcode = findPostcode(searchData.location);
    if (!searchData.postcode) {
      if (
        !homonymousStatesList.includes(locationSearchKeyword) &&
        !searchData.county
      ) {
        filteredCountries = [];
      } else {
        filteredCountries = countyFilter(activities, locationSearchKeyword);
      }
      data = await getLocationAction({
        query: searchData.location || searchData.county,
        queryType: searchData.county ? "county" : "city",
      });
    } else {
      data = await getLocationAction({
        query: searchData.postcode,
        queryType: "postcode",
      });
    }

    // check if postcodes.io can't find the place and if so then use cityFilter
    if (!data?.result?.length || data?.result.length === 0) {
      const foundCities = cityFilter(activities);
      if (foundCities?.length > 0) {
        data.result = foundCities;
      }
      // and put counties at the front
      if (filteredCountries?.length > 0) {
        data.result = filteredCountries.concat(data.result);
      }
    }

    const { result } = data;

    const filtered = sortByLocation({
      locationArr: result,
      listings: activities,
      distanceRange: searchData.distance,
      selectedCity: searchData.location,
      sortByDistance: searchData.sortBy === "nearest",
      queryType: searchData.postcode
        ? "postcode"
        : searchData.county
        ? "county"
        : "city",
    });

    return filtered.concat(filteredCountries);
  };

  const handleFilter = async (activities = spendActivities) => {
    // includeNationalOffers   => national + filter others
    // includeNationalOffers X => all go to filters

    let filteredData = activities;

    setFiltering(true);

    const nationalActivities = filteredData.filter(
      (activity) =>
        activity.locationType === spendActivitiesLocationTypesKeys.NATIONAL,
    );

    const nonNationalActivities = filteredData.filter(
      (activity) =>
        activity.locationType !== spendActivitiesLocationTypesKeys.NATIONAL,
    );

    if (searchData.tab !== "online") {
      const filteredDataByLocation = await locationFilter(
        searchData.includeNationalOffers ? nonNationalActivities : activities,
      );
      if (searchData.includeNationalOffers) {
        filteredData = [...filteredDataByLocation, ...nationalActivities];
      } else {
        filteredData = filteredDataByLocation;
      }
    }

    setFilteredActivities(filteredData);
    setFiltering(false);
  };

  const handleSearch = async (_searchData = searchData) => {
    setFiltering(true);

    const activities = await getSpendActivitiesAction({
      isPublic: true,
      keyword: getQuery(searchParams).keyword,
      language,
      availableOffersOnly: _searchData.availableOffersOnly,
      selectedActivity: _searchData.selectedActivity,
      tab: _searchData.tab,
      sortBy: _searchData.sortBy,
      location: _searchData.location,
      postcode: _searchData.postcode,
      distance: _searchData.distance,
      county: _searchData.county,
      includeNationalOffers: _searchData.includeNationalOffers,
    });
    handleFilter(activities);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      const stringifyQuery = generateQuery(searchData, searchParams);
      window.history.replaceState(
        searchData,
        "spend",
        GENERAL.SPEND.replace(":search?", stringifyQuery),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchData.location,
    searchData.postcode,
    searchData.keyword,
    searchData.selectedActivity,
    searchData.page,
    searchData.tab,
    searchData.distance,
    searchData.includeNationalOffers,
    searchData.availableOffersOnly,
    searchData.county,
    searchData.sortBy,
  ]);

  useEffect(() => {
    // this will do the initial search, and then only if tab, language or sortBy changes
    handleSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.tab, language, searchData.sortBy, searchData.tracker]);

  useEffect(() => {
    setAvailableActivitiesTypesArr(
      [
        ...(language === "english"
          ? spendActivityTypesEnglish
          : spendActivitiesDropDownWelsh),
      ].map(({ label, value }) => ({
        label: value,
        name: label,
      })),
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const handleSearchForm = (e) => {
    e.preventDefault();
    setSearchData((_data) => ({ ..._data, page: 1 }));
    handleSearch();
  };

  const handleActivity = (selected) => {
    if (!selected) return;
    const { value } = selected;
    if (!value) return;
    const updatedSelectedActivitiesObj = {};
    const selectedActivitiesNames = value.map(({ label }) => {
      updatedSelectedActivitiesObj[label] = label;
      return encodeURIComponent(label);
    });
    const selectedActivity = selectedActivitiesNames.join("&selectedActivity=");
    setSearchData((_data) => ({
      ..._data,
      selectedActivity,
    }));
  };

  const handleLocation = (selected = "", section) => {
    setSearchData((_data) => ({
      ..._data,
      county: section === "county" ? selected : "",
      location: section === "location" ? selected : "",
      postcode: selected ? "" : searchData.postcode,
      distance: selected ? _data.distance || 50 : 0,
      sortBy:
        _data.sortBy === "nearest" && !selected ? "popularity" : _data.sortBy,
      page: _data.sortBy === "nearest" && !selected ? 1 : _data.page,
    }));
  };

  const handlePage = (action) => {
    if (
      action === "incr" &&
      searchData.page < Math.ceil(filteredActivities.length / 12)
    ) {
      cardsSectionRef?.current?.scrollIntoView({ behavior: "smooth" });
      return setSearchData(({ page, ..._data }) => ({
        ..._data,
        page: page + 1,
      }));
    }
    if (searchData.page === 1) {
      return null;
    }
    cardsSectionRef?.current?.scrollIntoView({ behavior: "smooth" });
    return setSearchData(({ page, ..._data }) => ({
      ..._data,
      page: page - 1,
    }));
  };

  const locationListCities = citiesList
    ?.sort()
    ?.filter((e) => !!e)
    ?.map((e) => ({
      value: e,
      label: e,
      section: "location",
    }));

  const locationListCounties = countiesList
    ?.sort()
    ?.filter((e) => !!e)
    ?.map((e) => ({
      value: e,
      label: e,
      section: "county",
    }));

  const isFiltersApplied =
    searchData.postcode ||
    searchData.location ||
    searchData.county ||
    searchData.keyword ||
    (Number(searchData.distance) && searchData.tab === "nearby") ||
    !searchData.includeNationalOffers ||
    searchData.availableOffersOnly ||
    searchData.selectedActivity ||
    searchData.sortBy !== "popularity";

  const isLocationApplied =
    searchData.postcode || searchData.location || searchData.county;

  return (
    <S.Wrapper onSubmit={handleSearchForm}>
      <Row mb={6}>
        <Col w={[4, 8, 8]}>
          <T.H40Caps color="midnight">
            {t("findActivitiesNearMe", language)}
          </T.H40Caps>
        </Col>
      </Row>
      <Row mb={4}>
        <Col w={[4, 8, 8]}>
          <TabsSmall
            tabs={topTabs}
            handleClick={handleTab}
            selected={searchData.tab}
            tabLineWidth="auto"
            tabSpace="30px"
          />
        </Col>
      </Row>
      <Row mb={4}>
        {searchData.tab === "nearby" && (
          <>
            <Col w={[4, 6, 4]} mb={4}>
              <InputField
                name="postcode"
                placeholder={`${t("typePostcode", language)}...`}
                label={t("searchByPostcode", language)}
                value={searchData.postcode}
                setValue={(e) => handleChange("postcode", e.target.value)}
                clearFunc={() => handleChange("postcode", "")}
              />
            </Col>
            <Col w={[4, 6, 4]} mb={4}>
              <Dropdown
                name="city"
                options={locationListCities}
                value={
                  searchData?.location && {
                    label: searchData.location,
                    value: searchData.location,
                  }
                }
                setValue={({ selected }) => {
                  handleLocation(selected?.value || "", "location");
                }}
                placeholder={`${t("select", language)}...`}
                label={t("searchByCityTown", language)}
                canCreate
                createLabel={t("searchIn", language)}
                isClearable
              />
            </Col>
            <Col w={[4, 6, 4]} mb={4}>
              <Dropdown
                name="county"
                options={locationListCounties}
                value={
                  searchData?.county && {
                    label: searchData.county,
                    value: searchData.county,
                  }
                }
                setValue={({ selected }) => {
                  handleLocation(selected?.value || "", "county");
                }}
                placeholder={`${t("select", language)}...`}
                label={t("searchByCounty", language)}
                canCreate
                createLabel={t("searchIn", language)}
                isClearable
              />
            </Col>
            <Col w={[4, 6, 4]} mb={4}>
              <InputField
                name="distance"
                placeholder={`${t("searchWithinArea", language)}...`}
                label={t("searchWithinArea", language)}
                value={searchData.distance}
                setValue={(e) => handleChange("distance", e.target.value)}
                clearFunc={() => handleChange("distance", "")}
                type="number"
                disabled={
                  !searchData.location &&
                  !searchData.county &&
                  !searchData.postcode
                }
              />
            </Col>
          </>
        )}
        <Col w={[4, 6, 4]} mb={4}>
          <InputField
            name="keyword"
            placeholder={`${t("typeHere", language)}...`}
            label={t("searchByKeyword", language)}
            value={searchData.keyword}
            setValue={(e) => handleChange("keyword", e.target.value)}
            clearFunc={() => handleChange("keyword", "")}
          />
        </Col>
        <Col w={[4, 6, 4]} mb={4}>
          <T.H16 color="midnight" mb="15" ml="15">
            {t("nationalOffers", language)}
          </T.H16>

          <Checkbox
            value={searchData.includeNationalOffers}
            setValue={(e) =>
              handleChange("includeNationalOffers", e.target.checked)
            }
            mt="5"
            ml="5"
            label={t("includeAllNationalOffers", language)}
            color="gray3"
          />
        </Col>
        <Col w={[4, 6, 4]} mb={4}>
          <T.H16 color="midnight" mb="15" ml="15">
            {t("availability", language)}
          </T.H16>

          <Checkbox
            value={searchData.availableOffersOnly}
            setValue={(e) =>
              handleChange("availableOffersOnly", e.target.checked)
            }
            mt="5"
            ml="5"
            label={t("onlyShowOffersCurrentlyAvailable", language)}
            color="gray3"
          />
        </Col>
      </Row>
      <Row mb={5} style={{ height: "100%" }}>
        <Col w={[4, 4, 4]} style={{ height: "100%" }}>
          <AutoComplete
            multiple
            fullWidth
            options={availableActivitiesTypesArr}
            filterSelectedOptions
            getOptionLabel={(option) => option.label}
            setValue={handleActivity}
            searchByActivity
            placeholder={t("activityType", language)}
            label={t("selectActivityType", language)}
          />
        </Col>
      </Row>
      <Row mb={7}>
        <Col w={[4, 6, 4]}>
          <Button primary size="l" type="submit">
            {t("search", language)}
          </Button>
        </Col>
        {isFiltersApplied && (
          <Col w={[4, 6, 4]}>
            <Button
              secondary
              bg="midnight"
              size="l"
              handleClick={() => {
                setSearchData((_searchData) => {
                  const newSearchData = {
                    ..._searchData,

                    postcode: initialState.postcode,
                    location: initialState.location,
                    county: initialState.county,
                    keyword: initialState.keyword,
                    selectedActivity: initialState.selectedActivity,
                    distance: initialState.distance,
                    includeNationalOffers: initialState.includeNationalOffers,
                    availableOffersOnly: initialState.availableOffersOnly,
                    sortBy: "popularity",
                    page: 1,
                    tracker: _searchData.tracker + 1,
                  };

                  return newSearchData;
                });
              }}
            >
              {t("clearFilters", language)}
            </Button>
          </Col>
        )}
      </Row>
      {/* empty div, to use for scroll to */}
      <div ref={cardsSectionRef} />
      <Row mb={4}>
        <Col w={[4, 12, 12]}>
          <S.SortByWrapper>
            <T.Body14B color="midnight" m="0">
              Sort by:{" "}
            </T.Body14B>
            <Divider vertical h="30px" w="1px" bgColor="gray3" m="0 16px" />
            <TabsSmall
              tabs={sortByTabs}
              handleClick={handleSortTab}
              selected={searchData.sortBy}
              tabLineWidth="auto"
              tabSpace="30px"
              disabledTabs={[
                searchData.tab === "online" ? "nearest" : "", // if online then disable nearest
                isLocationApplied || userPostcode ? "" : "nearest", // if no location selected and no postcode then disable nearest
                searchData?.keyword ? "" : "relevance", // if no keyword then disable relevance
              ]}
              smallFont
            />
          </S.SortByWrapper>
        </Col>
      </Row>
      <Row>
        {filtering || getSpendActivitiesLoading ? (
          <Col w={[4, 12, 12]}>
            <Loading center />
          </Col>
        ) : (
          <>
            {(!isFiltersApplied ? lastFeaturedActivities : []).map(
              (activity) => (
                <Col w={[4, 6, 4]} style={{ marginBottom: "15px" }}>
                  <EarnSpendOpportunity
                    path={GENERAL.SPEND_ACTIVITY.replace(":id", activity.id)}
                    imgUrl={activity.imageURL}
                    title={activity.name}
                    subtitle={activity.subTitle}
                    credits={activity.cost}
                    venue={activity.spendVenue.name}
                    cities={activity.city}
                    isOnline={activity.isOnline}
                    featuredAt={activity.featuredAt}
                    costVaries={activity.costVaries}
                    soldOut={
                      activity.soldOut ||
                      (!activity.turnUpAtVenue &&
                        !activity.callToBook &&
                        !Number(activity.numberOfAvailableCodes) > 0)
                    }
                    type="spend"
                  />
                </Col>
              ),
            )}
            {filteredActivities.length > 0 ? (
              filteredActivities
                .filter(({ featuredAt }) =>
                  !isFiltersApplied ? !featuredAt : true,
                )
                .sort(({ featuredAt: a }, { featuredAt: b }) =>
                  new Date(a) > new Date(b) ? -1 : 0,
                )
                .slice((searchData.page - 1) * 12, searchData.page * 12)
                .map((activity) => (
                  <Col w={[4, 6, 4]} style={{ marginBottom: "15px" }}>
                    <EarnSpendOpportunity
                      path={GENERAL.SPEND_ACTIVITY.replace(":id", activity.id)}
                      imgUrl={activity.imageURL}
                      title={activity.name}
                      subtitle={activity.subTitle}
                      credits={activity.cost}
                      venue={activity.spendVenue.name}
                      cities={activity.city}
                      isOnline={activity.isOnline}
                      featuredAt={activity.featuredAt}
                      costVaries={activity.costVaries}
                      soldOut={
                        activity.soldOut ||
                        (!activity.turnUpAtVenue &&
                          !activity.callToBook &&
                          !Number(activity.numberOfAvailableCodes) > 0)
                      }
                      type="spend"
                    />
                  </Col>
                ))
            ) : (
              <T.Body16B color="midnight" m="0" ml="10">
                {t("noActivitiesFound", language)}
              </T.Body16B>
            )}
          </>
        )}
      </Row>
      <Row jc="space-between">
        <Col
          w={[4, 12, 12]}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {searchData.page > 1 && (
            <S.PageButton prev onClick={() => handlePage("decr")} type="button">
              <T.LinkB16 color="blue">{t("back", language)}</T.LinkB16>
            </S.PageButton>
          )}
          {searchData.page < Math.ceil(filteredActivities.length / 12) && (
            <S.PageButton onClick={() => handlePage("incr")} type="button">
              <T.LinkB16 color="blue">{t("next", language)}</T.LinkB16>
            </S.PageButton>
          )}
        </Col>
      </Row>
    </S.Wrapper>
  );
};

const SearchPramsProvider = (props) => {
  const { search } = useParams();
  const searchParams = new URLSearchParams(search);
  const searchQuery = getQuery(searchParams);

  const data = {
    ...initialState,
    ...searchQuery,
    includeNationalOffers: ["true", true].includes(
      searchQuery.includeNationalOffers,
    ),
    availableOffersOnly: ["true", true].includes(
      searchQuery.availableOffersOnly,
    ),
  };

  return <Spend {...props} initialState={data} searchParams={searchParams} />;
};

const mapStateToProps = (state) => ({
  spendActivities: state.activities.spendActivities,
  lastFeaturedActivities: state.activities.lastFeaturedActivities,
  citiesList: state.activities.citiesList,
  countiesList: state.activities.countiesList,
  homonymousStatesList: getListsIntersectionValues(
    state.earnGroups.citiesList,
    state.earnGroups.countiesList,
  ),
  spendActivitiesLoading:
    state.activities.getSpendActivitiesLoading ||
    state.location.getLocationLoading,
  foundLocation: state.location.result,

  language: state.auth.decideLanguage(state.auth),
  userPostcode: state.auth?.profile?.postcode,
});

const mapActionToProps = {
  // ACTION NEEDS TO BE UPDATED
  getSpendActivitiesAction: getSpendActivities,
  getLocationAction: getLocation,
};

export default connect(mapStateToProps, mapActionToProps)(SearchPramsProvider);
