import {
  GET_LOCATION_LOADING_START,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAIL,
  GET_LOCATION_LOADING_END,
} from "../types";

import { location } from "../../api-calls/thirdParty";

// gets location results for search queries (pages: earn and spend search)
// queryType is either postcode or place
export const getLocation =
  ({ query, queryType }) =>
  async (dispatch) => {
    dispatch({ type: GET_LOCATION_LOADING_START });

    try {
      let res = {};
      if (queryType === "postcode") {
        res = await location.getLocationByPostcode({ query });
      } else if (queryType === "county" || queryType === "city") {
        res = await location.getLocationByPlace({ query, type: queryType });
      } else if (queryType === "bulkPostcodes") {
        res = await location.getLocationsByBulkPostcodes({ query });
      } else throw new Error("Missing or invalid query type");

      const { data, error } = res;

      if (data) {
        dispatch({
          type: GET_LOCATION_SUCCESS,
          payload: data,
        });
        return data;
      }
      if (error) {
        dispatch({
          type: GET_LOCATION_FAIL,
          payload: error,
        });
        return error;
      }
    } catch (error) {
      dispatch({
        type: GET_LOCATION_FAIL,
        payload: error,
      });
      return error;
    } finally {
      dispatch({ type: GET_LOCATION_LOADING_END });
    }
  };
