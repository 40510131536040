// TODO: create loading reducer that stores all API request states!

import {
  // Spend activities
  GET_SPEND_ACTIVITIES_LOADING_START,
  GET_SPEND_ACTIVITIES_LOADING_END,
  GET_SPEND_ACTIVITIES_SUCCESS,
  GET_SPEND_ACTIVITIES_FAIL,
  GET_ACTIVITIES_CITES_COUNTIES_LOADING_START,
  GET_ACTIVITIES_CITES_COUNTIES_LOADING_END,
  GET_ACTIVITIES_CITES_COUNTIES_SUCCESS,
  GET_ACTIVITIES_CITES_COUNTIES_FAIL,
} from "../types";

const initState = {
  getSpendActivitiesLoading: false,
  error: null,
  spendActivities: [],
  lastFeaturedActivities: [],
  citiesList: [],
  countiesList: [],
};

const activitiesReducer = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    // GET SPEND ACTIVITIES
    case GET_SPEND_ACTIVITIES_LOADING_START:
      return {
        ...state,
        getSpendActivitiesLoading: true,
      };
    case GET_SPEND_ACTIVITIES_LOADING_END:
      return {
        ...state,
        getSpendActivitiesLoading: false,
      };
    case GET_SPEND_ACTIVITIES_SUCCESS:
      return {
        ...state,
        spendActivities: payload.spendActivities,
        lastFeaturedActivities: payload.lastFeaturedActivities,
        getSpendActivitiesLoading: false,
        citiesList: payload.citiesList?.cities?.length
          ? [
              ...new Set([payload?.location, ...payload.citiesList.cities]).map(
                (e) => e?.trim(),
              ),
            ]
          : state.citiesList,
        countiesList: payload.citiesList?.counties?.length
          ? [
              ...new Set([payload?.county, ...payload.citiesList.counties]).map(
                (e) => e?.trim(),
              ),
            ]
          : state.countiesList,
      };
    case GET_SPEND_ACTIVITIES_FAIL:
      return {
        ...state,
        error: payload,
      };
    case GET_ACTIVITIES_CITES_COUNTIES_LOADING_START:
      return {
        ...state,
        getActivitiesCitesAndCountiesLoading: true,
      };

    case GET_ACTIVITIES_CITES_COUNTIES_LOADING_END:
      return {
        ...state,
        getActivitiesCitesAndCountiesLoading: false,
      };

    case GET_ACTIVITIES_CITES_COUNTIES_SUCCESS:
      return {
        ...state,
        citesAndCounties: payload,
        getActivitiesCitesAndCountiesLoading: false,
      };
    case GET_ACTIVITIES_CITES_COUNTIES_FAIL:
      return {
        ...state,
        error: payload,
        getActivitiesCitesAndCountiesLoading: false,
      };

    default:
      return state;
  }
};

export default activitiesReducer;
