export default {
  tempo: "Tempo",
  needAccount:
    "Mae’n rhaid bod gennych gyfrif Tempo i ddefnyddio Credydau Amser ar y gweithgaredd yma",
  time: "Amser",
  credits: "Credydau",
  creditsNumInFront: "Credyd",
  timeCredits: "Credydau Amser",
  timeCreditsNumInFront: "Credyd Amser",
  TCdelivery: "Darpariaeth Credydau Amser",
  costs: "Gall Costau",
  vary: "Amrywio",
  not: "Dim",
  costsVaries: "Gall Costau Amrywio",
  needToKnow: "Beth sydd angen i mi ei wybod cyn ymweld",
  spendTC: "Defnyddio Credydau Amser Tempo",
  findOutMore: "Darganfod Mwy",
  loadMore: "Llwytho Mwy",
  groupsBookInAdvance: "Mae angen i grwpiau archebu o flaen llaw",
  groupsDontBookInAdvance: "Nid oes angen i grwpiau archebu o flaen llaw",
  maxGroupSize: "Uchafswm maint y grwp yw",
  back: "Yn ôl",
  activities: "Gweithgareddau",
  TCNumber: "Nifer o Gredydau Amser",
  details: "Manylion",
  shareActivity: "Rhannu gweithgaredd",
  pickOneOption:
    "Dewisiwch un opsiwn er mwyn defnyddio Credydau Amser Tempo gyda'ch Rhif Adnabod",
  pickOneOptionShort: "Dewisiwch un opsiwn i ddefnyddio Credydau Amser",
  turnUp: "Trowch lan i’r lleoliad",
  callEmail: "Ffoniwch neu gyrrwch ebost i archebu",
  getOnlineBooking: "Derbyn côd archebu ar-lein",
  turnUpWithID: "Trowch fyny gyda'ch rhif adnabod",
  noNeedToBook: "Does dim angen archebu",
  arriveAtVenue: "Cyrhaeddwch y lleoliad",
  checkAvailability: "Cofiwch wirio argaeledd ac unrhyw gyfyngiadau",
  tellThemActivity:
    "Gadewch iddynt wybod pa weithgaredd yr hoffech roi cynnig arno gyda Chredydau Amser",
  giveID: "Rhowch eich Rhif Adnabod Tempo iddynt",
  automaticallyDeducted:
    "Caiff y nifer cywir o Gredydau Amser ei dynnu o'ch cyfrif cyn iddynt gadarnhau eich archeb",
  haveFun: "Mwynhewch",
  bookAdvance: "Archebu o flaen llaw",
  contactVenueByPhone: "Cysylltwch â'r lleoliad dros y ffôn",
  contactVenueByEmail: "Cysylltwch â'r lleoliad drwy ebost",
  orByEmail: "neu drwy ebost",
  email: "Cyfeiriad e-bost",
  forMoreInfo: "am fwy o gefnogaeth",
  includeDates: "cynnwyswch y dyddiadau, amseroedd a’r nifer o bobl",
  theyWillTakeTC:
    "Caiff y nifer cywir o Gredydau Amser ei dynnu o'ch cyfrif cyn iddynt gadarnhau eich archeb",
  furtherInfo: "Gwybodaeth Pellach",
  getAccessCodeOnline: "Derbynnwch god mynediad ar-lein gyda'ch Credydau Amser",
  numberOfPeople: "Nifer o bobl",
  adults: "Oedolion",
  over16: "Dros 16",
  children: "Plant",
  under16: "O dan 16",
  clickGetAccess:
    "Cliciwch 'Derbyn Cod Mynediad' isod. Caiff Credydau Amser eu tynnu o'ch cyfrif a bydd eich côd yn cael ei ebostio i chi gyda chyfarwyddiadau i'w defnyddio",
  notEnoughTC: "Nid oes gennych ddigon o Gredydau Amser ar gael yn eich cyfrif",
  thisWillCost: "Bydd hyn yn costio",
  getCode: "Derbyn Cod Mynediad",
  yourAccessCodes: "Eich Codau Mynediad",
  accessCodes: "Codau Mynediad",
  youveGotCodes:
    "Mae gennych {CUSTOM1} côd mynediad! Nodwch nhw i lawr ar gyfer eich cofnodion, os oes angen. Maent hefyd wedi cael eu hebostio i chi",
  redeemCodes: "Sut ydw i’n defnyddio fy nghôd mynediad",
  visitVenueWebsite:
    "Ymwelwch â wefan y lleoliad a mewnfudwch eich côd mynediad pan fyddwch yn ‘talu’ er mwyn archebu eich tocynnau sinema",
  backToSpend: "Ewch yn ôl i Defnyddio Credydau Amser",
  unsuccessful: "Aflwyddianus",
  ohNoShort: "O na! Nid oedd y taliad yn llwyddiannus",
  ohNo: "O na! Nid oedd y taliad yn llwyddiannus. Nid oes gennych ddigon o Gredydau Amser",
  goBack: "Yn ôl",
  currentRoles: "Rolau ar gael",
  currentlyHiringFor: "Ar hyn o bryd rydym yn edrych i lenwi'r rolau canlynol",
  lookingForHelp: "Rydym bob amser yn chwilio am gymorth gyda",
  lotsOfWays:
    "Mae nifer o ffyrdd i gymryd rhan. Cysylltwch â ni i rannu eich argaeledd a sut y gallwch helpu",
  volunteerHere: "Gwirfoddolwch Yma",
  getInTouch: "Cysylltwch â ni",
  backToEarn: "Ewch yn ôl i 'Ennill Credydau Amser'",
  multipleLocations: "Sawl lleoliad",
  online: "Ar-lein",
  createMemberAccount: "Creu Cyfrif Rhif Adnabod",
  mustHaveAccount:
    "Mae’n rhaid bod gennych gyfrif Tempo i ddefnyddio Credydau Amser ar y gweithgaredd yma",
  createAccount: "Creu cyfrif",
  alreadyHaveAccount: "Eisioes â chyfrif gwirfoddoli",
  logIn: "Mewngofnodi",
  about: "Am",
  noActivitiesListed: "Dim gweithgareddau wedi'u rhestru ar hyn o bryd",
  viewAccessibility: "Gweld Gwybodaeth Hygyrchedd ar gyfer y lleoliad yma",
  noSpendOptions:
    "Nid oes opsiwn i ddefnyddio Credydau Amser ar gyfer y gweithgaredd hwn ar hyn o bryd. Dewch nôl yn fuan os gwelwch yn dda",
  contactVenueBefore: "Cysylltwch â'r lleoliad cyn i chi fynd",
  singleAccessCode:
    "Dyma eich cod mynediad! Nodwch y cod ar gyfer eich recordiau os oes angen. Mae hefyd wedi ei ebostio i chi",
  noDetails: "Dim manylion wedi eu darparu",

  //   ERROR MESSAGES
  // maxNumPerMember:
  //   "Rydych wedi cyrraedd yr uchafswm o {CUSTOM1} o docynnau ar gyfer y gweithgaredd hwn",
  // maxReached:
  //   "Rydych wedi cyrraedd yr uchafswm o {CUSTOM1} o docynnau ar gyfer y gweithgaredd hwn",
  notEnoughCodes: "Does dim digon o godau ar gael. Mae {CUSTOM1} o godau ar ol",
  notEnoughTCs:
    "Nid oes gennych ddigon o Gredydau Amser ar gael yn eich cyfrif",

  //   TIMES
  anytime: "Unrhyw amser",
  today: "Heddiw",
  tomorrow: "Yfory",
  Monday: "Dydd Llun",
  Tuesday: "Dydd Mawrth",
  Wednesday: "Dydd Mercher",
  Thursday: "Dydd Iau",
  Friday: "Dydd Gwener",
  Saturday: "Dydd Sadwrn",
  Sunday: "Dydd Sul",
  January: "Ionawr",
  February: "Chwefror",
  March: "Mawrth",
  April: "Ebrill",
  May: "Mai",
  June: "Mehefin",
  July: "Gorffenaf",
  August: "Awst",
  September: "Medi",
  October: "Hydref",
  November: "Tachwedd",
  December: "Rhagfyr",
  // weekdaysShort
  Sun: "Sul",
  Mon: "Llun",
  Tue: "Maw",
  Wed: "Merch",
  Thu: "Iau",
  Fri: "Gwen",
  Sat: "Sad",

  // Log in page
  typeEmail: "Teipiwch eich cyfeiriad e-bost yma",
  password: "Cyfrinair",
  typePassword: "Teipiwch eich cyfrinair yma",
  forgotPassword: "Wedi anghofio'ch cyfrinair",
  recaptcha:
    "Diogelir y safle hwn gan reCAPTCHA ac mae | Polisi Preifatrwydd | a | Thelerau Gwasanaeth | Google yn berthnasol",
  clickToAcceptTimeCredits: "Cliciwch yma, i dderbyn Credydau Amser",
  welcomeToTC: "CROESO I GREDYDAU AMSER TEMPO",
  earnTempoTCs:
    "Enillwch Gredydau Amser Tempo | trwy roi o'ch amser i achosion da",
  spendTempoTCs:
    "Defnyddiwch Gredydau Amser Tempo | ar weithgareddau difyr gyda'ch teulu a'ch ffrindiau",

  // sign up
  signUp: "Cofrestru",
  alreadyHaveAccountLogIn: "A oes gennych chi gyfrif eisoes? | Mewngofnodi",
  firstName: "Enw Cyntaf",
  typeFirstName: "Teipiwch eich enw cyntaf yma",
  typeFullName: "w- Type your full name",
  lastName: "Cyfenw",
  typeLastName: "Teipiwch eich cyfenw yma",
  typeEmailAddress: "Teipiwch eich cyfeiriad e-bost yma",
  passwordContain: "Mae'n rhaid i gyfrinair gynnwys",
  min8characters: "O leiaf 8 nod",
  capitalLetter: "Un priflythyren",
  lowercaseLetter: "Un islythyren",
  oneNumber: "Un rhif",
  createPassword: "Crëwch gyfrinair yma",
  nameOfOrg: "Enw eich sefydliad",
  typeYourOrg: "Teipiwch enw eich sefydliad yma",
  typeOfOrg: "Math o sefydliad",
  whatFocus: "Beth ydych chi'n canolbwyntio arno?",
  selectUpToThree: "Dewiswch hyd at dri",
  region: "Rhanbarth",
  selectRegion: "Dewiswch eich rhanbarth",
  localAuthority: "Awdurdod lleol",
  selectLocalAuthority: "Dewiswch eich awdurdod lleol",
  phoneNumber: "Rhif ffôn",
  typeYourPhoneNumber: "Teipiwch eich rhif ffôn yma",
  agreeToTerms:
    "Rwyf yn cytuno â | Thelerau Defnydd Tempo | . Trwy glicio Cofrestru, rwyf yn cydnabod | Polisi Preifatrwydd Tempo",
  mustAgree: "Mae'n rhaid i chi gytuno â'n telerau ac amodau",
  fieldRequired: "Mae'r maes hwn yn ofynnol",
  welcome: "Croeso",
  VolunteeringWithMe: "Yn gwirfoddoli gyda mi ar hyn o bryd",
  nowPartOfNetwork:
    "Rydych chi bellach yn rhan o rwydwaith cenedlaethol o sefydliadau sy'n defnyddio Credydau Amser i gynnwys pobl mewn cymunedau sy'n creu newid cymdeithasol cadarnhaol",
  giveOutTCToSayThanks: "Rhowch Gredydau Amser Tempo i ddweud diolch yn syth!",
  giveOutTempoTC: "Rhowch Gredydau Amser Tempo",
  toSayThanks: "i ddweud diolch yn syth",
  checkTCBalance: "Gwiriwch eich balans Credydau Amser Tempo",
  knowHowMany: "Fel y byddwch bob amser yn gwybod faint sydd ar ôl gennych",
  invitePeople: "Gwahoddwch bobl yn hawdd",
  toEarnTC:
    "i ennill Credydau Amser, gwirio'u balans a darganfod ffyrdd difyr i'w defnyddio",
  letsGo: "Ymlaen â ni",
  giveOutTC: "Dosbarthu Credydau Amser",
  acceptTempoTC: "Derbyn Credydau Amser Tempo",
  getMoreTC: "Cael gafael ar ragor o Gredydau Amser",
  TempoTCEarned: "Credydau Amser Tempo a Enillwyd",
  TempoTCSpent: "Credydau Amser Tempo a ddefnyddiwyd",
  transactionsWith: "Trafodion gyda",
  viewMembers: "Gweld fy Ngwirfoddolwyr",
  inviteMembers: "Gwahodd Gwirfoddolwr Newydd",
  noRecords: "Dim recordiau i ddangos",
  loading: "Llwytho",
  typeOfActivity: "Math o weithgaredd",
  balance: "Balans",
  menu: "Dewislen",
  welcomeBack: "Croeso nôl",
  month: "Mis",
  thisMonth: "Y mis yma",
  year: "Blwyddyn",
  allTime: "Yr Holl Amser",
  tcSpentByMembers:
    "Credydau amser Tempo a ddefnyddiwyd gan eich gwirfoddolwyr",
  tcGivenOut: "Credyd Amser a Roddwyd",
  tcGivenToOthers:
    "Credydau amser Tempo a roddwyd i eraill gan eich gwirfoddolwyr",
  newMembers: "Gwirfoddolwyr Newydd A Gofrestrwyd",
  name: "Enw",
  joined: "Wedi ymuno",
  tcEarned: "Credydau Amser a Enillwyd",
  tcSpent: "% Credydau Amser a ddefnyddiwyd",
  remove: "Dileu",
  rejoin: "Ailymuno",
  viewMore: "Gweld Mwy",
  tcHistory: "Hanes Credydau Amser Tempo",
  tcBalance: "Balans Credyd Amser",
  date: "Dyddiad",
  activity: "Gweithgaredd",
  numberOfTC: "# Credydau Amser",
  activityDate: "Gweithgaredd / Dyddiad ",
  dateLastTc: "Y dyddiad y rhoddwyd Credydau Amser ddiwethaf",
  dateLastGiveOutTc: "Dyddiad olaf y dosbarthwyd gredydau amser",
  type: "Math",
  givenOut: "Rhoddwyd",
  received: "Derbyniwyd",
  exchanged: "Cyfnewidiwyd",
  pending: "Yn yr arfaeth",
  members: "Gwirfoddolwyr",
  myMembers: "Fy ngwirfoddolwyr",
  signedUpAtMoment: "Wedi cofrestru ar hyn o bryd",
  nameJoined: "Enw / Ymuno",
  exportData: "Allforio Data",
  reAdd: "Ail-ychwanegu",
  recordStat: "{CUSTOM1} o {CUSTOM2} gofnod(ion)",
  chooseCategory: "Dewiswch gategori",
  somethingElse: "Rhywbeth arall",
  onlyUse:
    "Defnyddiwch hyn dim ond os nad yw'r categorïau eraill yn berthnasol",
  addActivityName:
    "A hoffech chi ychwanegu enw neu ddyddiad y gweithgaredd ar gyfer eich cofnodion",
  optional: "Dewisol",
  egCoffee: "e.e. Clwb Coffi neu Grŵp Garddio",
  startDate: "Dyddiad dechrau",
  endDate: "Dyddiad gorffen",
  nowChoose: "Nawr dewiswch pwy i roi Credydau Amser Tempo iddo",
  recogniseHours: "Mae Credydau Amser Tempo yn cydnabod oriau amser",
  oneTimeCreditCanBeGiven:
    "Gellir rhoi un Credyd Amser am leiafswm o 1 awr o wirfoddoli. I reoli eich balans Credydau Amser, gallwch roi llai o Gredydau Amser na nifer yr oriau y mae rhywun wedi eu cyfrannu",
  whoGiveTCTo: "I bwy yr hoffech chi roi Credydau Amser Tempo",
  selectMembers: "Dewiswch eich gwirfoddolw(y)r",
  selectAll: "Dewis pob un",
  howManyTCsGiveOut: "Faint o Gredydau Amser yr hoffech chi eu rhoi",
  noMembers: "Dim gwirfoddolwyr wedi'u hychwanegu hyd yma",
  totalTC: "Cyfanswm Credydau Amser",
  remainingBalance: "Eich balans sy'n weddill",
  sayThankYou: "Dywedwch ddiolch a rhowch Gredydau Amser Tempo",
  applyAll: "Cymhwyso popeth",
  woohoo: "Llwyddiant",
  youHaveThankedYourMembers: `Rydych wedi diolch i'ch gwirfoddolwyr a rhoi Credydau Amser iddynt. Mae gennych {CUSTOM1} o Gredydau Amser ar ôl yn eich cyfrif`,
  viewDashboard: "Gweld y Dangosfwrdd",
  giveOutMore: "Rhoi Mwy",
  mostPopularInUK: "Mwyaf Poblogaidd yn y Deyrnas Unedig",
  sendYourUnique: "Anfon eich dolen wahodd unigryw",
  copy: "Copïo",
  successfullyCopied: "Wedi copïo'n llwyddiannus",
  sendUniqueLinkExplanation:
    "Anfonwch eich dolen wahodd unigryw at eich gwirfoddolwyr trwy neges e-bost, neges destun, cyfryngau cymdeithasol neu hyd yn oed drwy ei hysgrifennu ar bapur! Mae'r ddolen hon wastad yn aros yr un fath - anfonwch hi gynifer o weithiau ag y dymunwch at gynifer o bobl ag y dymunwch",
  membersNeedEmailAddressExplanation:
    "Mi fydd angen cyfeiriad ebost ar y gwirfoddolwr er mwyn creu cyfrif arlein gyda Tempo. Pan fyddant wedi creu cyfrif mi fyddant yn medru derbyn Credydau Amser Tempo gennych, gwirio eu balans yn hawdd a darganfod ffyrdd difyr i ddefnyddio eu Credydau Amser Tempo",
  whatIfNoEmail: "Beth os nad oes gan fy ngwirfoddolwr newydd gyfeiriad e-bost",
  clickToFindOut: "Cliciwch yma i gael gwybod",
  inviteMemberNoEmail:
    "GWAHODD GWIRFODDOLWR NEWYDD NAD OES GANDDYNT GYFEIRIAD E-BOST",
  membershipCardExplanation:
    "Os nad oes gan wirfoddolwr gyfeiriad ebost gallent gael mynediad at eu Credydau Amser gyda cherdyn Rhif Adnabod. Mewnbynnwch eu manylion isod",
  dateOfBirth: "Dyddiad geni",
  ok: "Iawn",
  cancel: "Canslo",
  postcode: "Côd post",
  typePostcode: "Teipiwch y côd post yma",
  typePhoneNumber: "Teipiwch y rhif ffôn yma",
  wantToGoBack: "Rydw i eisiau mynd yn ôl",
  registerMember: "Cofrestru Gwirfoddolwr",
  areYouSureEmail:
    "Ydych chi'n siŵr nad oes gan eich gwirfoddolwr gyfeiriad e-bost",
  ifTheyDoHaveEmail:
    "Os oes ganddynt  gyfeiriad e-bost, fe ddylsent gofrestru gan ddefnyddio'ch dolen wahodd unigryw chi ar y dudalen flaenorol fel y gallant wirio eu cyfrif a gweld eu balans a'u gweithgarwch",
  imSure: "Rydw i'n siŵr",
  membershipID: "Rhif Adnabod",
  nowUseMembershipID:
    "Grêt! Mi fedrith y gwirfoddolwr yma ddefnyddio eu Rhif Adnabod i gyrchu Credydau Amser Tempo",
  whenArriveAtSpendVenue:
    "Pan fyddant yn cyrraedd lleoliad y Partner Cydnabyddiaeth mi fydd angen iddynt rannu eu Rhif Adnabod",
  pleaseWriteMembershipID:
    "Ysgrifennwch eu Rhif Adnabod ar un o'r cardiau a ddarperir a rhowch ef iddynt",
  understandAndGiveMembershipID:
    "Rwyf yn deallt ac wedi rhoi ei R/hif Adnabod i'r Gwirfoddolwr",
  search: "Chwilio",
  accepted: "Wedi ei dderbyn",
  exportDate: "Dyddiad Allforio",
  dateActivity: "Dyddiad Gweithgaredd",
  yesterday: "Ddoe",
  getMoreTempoTC: "Cael gafael ar ragor o Gredydau Amaser Tempo",
  howManyTCs: "Faint o Gredydau Amser yr hoffech chi eu cael",
  selectPackage: "Dewiswch un o'r pecynnau isod",
  discussThisFurther:
    "Os hoffech chi drafod hyn ymhellach, cysylltwch â ni ar 0292 056 6132",
  requestReceived: "CAIS WEDI'I DDERBYN",
  requestLogged:
    "Mae eich cais wedi cael ei gofnodi. Bydd Tempo yn ei brosesu o fewn y 2 diwrnod gwaith nesaf. Byddwch yn cael neges e-bost yn rhoi gwybod i chi am y canlyniad. I gysylltu â ni ynglŷn â hyn, ffoniwch 0292 056 6132",
  requestLogged2:
    "Mae eich cais wedi cael ei gofnodi. Bydd Tempo yn ei brosesu o fewn y 5 ddiwrnod gwaith nesaf. Byddwch yn cael neges e-bost yn rhoi gwybod i chi am y canlyniad. I gysylltu â ni ynglŷn â hyn, ffoniwch 0292 056 6132",
  letPeopleKnow: "RHOWCH WYBOD I BOBL Y GALLANT ENNILL CREDYDAU AMSER GYDA CHI",
  provideDetailsAboutOrg:
    "Rhowch fanylion am eich sefydliad a'r mathau o weithgareddau gwirfoddoli rydych chi'n eu cynnal fel bod darpar wirfoddolwyr yn gallu dod o hyd i chi a chysylltu",
  addVersionInWelsh: "Ychwanegwch fersiwn Gymraeg",
  orgName: "Enw eich Sefydliad",
  organisation: "Sefydliad",
  typeOrgName: "Teipiwch enw eich sefydliad yma",
  typeOrgTypeHere: "Teipiwch enw eich sefydliad yma",
  focuses: "Yn canolbwyntio ar",
  warning: "Rhybudd",
  upToThree: "hyd at dri",
  whereCanPeopleEarn: "Ble all pobl ennill Credydau Amser gyda chi",
  weDontHaveAddress: "Mae ein gwirfoddoli'n cael ei wneud o bell",
  hideFullAddress: "Cuddio ein cyfeiriad llawn (dangos tref/dinas yn unig)",
  addressLine1: "Llinell Gyfeiriad 1",
  addressLine2: "Llinell Gyfeiriad 2",
  townCity: "Tref / Dinas",
  maxCharacters: "{CUSTOM1} o nodau ar y mwyaf",
  addAnotherLocation: "Ychwanegwch leoliad arall",
  typeAddress: "Teipiwch y cyfeiriad",
  typeTownCity: "Teipiwch y dref / y ddinas",
  tellPotentialVolunteersAboutOrg:
    "Rhowch wybod i ddarpar wirfoddolwyr am eich sefydliad",
  typeHere: "Teipiwch yma",
  charactersUsed: "Nodau wedi'u defnyddio",
  howShouldPeopleGetInTouch:
    "Sut dylai pobl gysylltu â chi am ennill Credydau Amser",
  contactEmail: "Cyfeiriad E-bost Cyswllt",
  typeEmailHere: "Teipiwch y cyfeiriad e-bost yma",
  contactNumber: "Rhif Ffôn Cyswllt",
  websiteSignUpLink: "Gwefan / Dolen Gofrestru",
  typeUrlHere: "Teipiwch yr url yma",
  typeFacebookHere: "Teipiwch yr url Facebook yma",
  facebookLink: "Dolen Facebook",
  twitterLink: "Dolen Twitter",
  typeTwitterHere: "Teipiwch yr url Twitter yma",
  dragLogoHere: "Llusgwch logo yma neu cliciwch",
  fileSize: "Uchafswm maint ffeil {CUSTOM1}",
  dragImageHere: "Llusgwch ddelwedd yma neu cliciwch",
  minDimensions: "Lleiafswm dimensiynau {CUSTOM1}",
  addSpecificActivities:
    "Ychwanegwch weithgareddau penodol rydych yn rhoi Credydau Amser ar eu cyfer",
  egBreakfastClub:
    "e.e. Clwb Brecwast neu Sifftiau Derbynfa. Ychwanegwch gynifer ag y dymunwch",
  activityName: "Enw'r Gweithgaredd",
  typeActivityName: "Teipiwch Enw'r Gweithgaredd",
  addDatesTimesSkills:
    "Ychwanegwch y dyddiadau, yr amserau neu'r sgiliau y mae eu hangen",
  addAnotherActivity: "Ychwanegwch weithgarwch arall",
  tickToMakePublic: "Ticiwch i wneud eich proffil yn gyhoeddus",
  saveChanges: "Arbed Newidiadau",
  viewProfile: "Gweld Proffil",
  profileSaved: "Proffil wedi'i Arbed",
  addressDescription:
    "Mae eich cyfeiriad yn helpu gwirfoddolwyr lleol i ddod o hyd i chi. Os yw eich gwirfoddoli yn cael ei wneud o bell neu os nad ydych eisiau rhannu eich cyfeiriad ar-lein, ticiwch y bocs perthnasol isod.",
  anythingElse: "Anything else they should know",
  selectTypesActivities:
    "Dewiswch y mathau o weithgareddau y gall gwirfoddolwyr eu gwneud",
  selectAsMany: "Dewiswch gynifer ag y mae arnoch eu hangen",
  accountDetails: "Manylion Cyfrif",
  emailAddress: "Cyfeiriad E-bost",
  resetPassword: "Ailosod Cyfrinair",
  orgDetails: "Manylion y Sefydliad",
  administrators: "Gweinyddwyr",
  administratorsExplanation:
    "Gall gweinyddwyr wneud popeth y gallwch chi ei wneud, heblaw am ychwanegu gweinyddwyr eraill. Rydych wedi ychwanegu {CUSTOM1} o weinyddwyr. Cewch ychwanegu {CUSTOM2} arall",
  dateLastIssued: "Y dyddiad y rhoddwyd Credydau Amser ddiwethaf",
  ofRecords: "Cofnod(ion) {CUSTOM1} o {CUSTOM2}",
  addNewAdmin: "Ychwanegwch weinyddwr newydd",
  add: "Ychwanegu",
  woohooSent:
    "LLWYDDIANT! RYDYCH WEDI ANFON DOLEN GOFRESTRU ATO/ATI TRWY NEGES E-BOST",
  areYouSureRemoveAdmin: "Ydych chi'n siŵr eich bod am ddileu'r gweinyddwr hwn",
  willBeforeName: "Bydd",
  willBeDeletedAsAdmin: "yn cael ei ddileu/dileu fel gweinyddwr",
  signUpSent: "Dolen gofrestru wedi'i hanfon",
  receivedEmailWithSignUpLink:
    "Mae {CUSTOM1} wedi derbyn neges e-bost sy'n cynnwys dolen gofrestru i osod cyfrinair. Gofynnwch iddo/iddi edrych ar ei negeseuon",
  ohNoNotApproved: "O na! Ni chymeradwywyd y trafodyn hwn",
  didYouEnterCorrectly:
    "A ddefnyddiwyd eu Rhif Adnabod cywir? Fe ddefnyddioch chi",
  pleaseAskCustomer: "Gofynnwch i'r Cwsmer wirio ei falans Credydau Amser cyn",
  tryingAgain: "ceisio eto",
  success: "Llwyddiant",
  dontForgetInternalBooking:
    "Cofiwch roi hyn trwy eich system archebu fewnol neu diliau fel y gallant gael mynediad ar ddiwrnod y gweithgaredd",
  transactionApproved:
    "Mae'r trafodyn wedi cael ei gymeradwyo ac mae'r Credydau Amser wedi cael eu didynnu o gyfrif y cwsmer",
  askCustomerForID: "Gofynnwch i'r Cwsmer am eu Rhif Adnabod",
  typeMembershipID: "Teipiwch eu Rhif Adnabod",
  canBeFound:
    "Maen hwn i'w weld ar eu cyfrif ar-lein neu eu Cerdyn Rhif Adnabod",
  chooseActivity: "Dewiswch weithgaredd",
  hasRestrictions: "Mae cyfyngiadau'n berthnasol",
  wantToAddSomething:
    "Eisiau ychwanegu rhywbeth yma? Cysylltwch â Tempo ar help@wearetempo.org neu 0292 056 6132 ac fe'i ychwangwn ar eich rhan",
  provideAsMuchDetail: "Rhowch gymaint o fanylion â phosibl",
  tcPerPerson: "Credydau Amser fesul unigolyn",
  pickNumberOfPeople: "Dewiswch neu teipiwch nifer y bobl",
  tcPerPersonHere: "Credydau Amser fesul unigolyn yma",
  earnTempoTC: "Ennill Credydau Amser Tempo",
  wantsYouToBecomeMember:
    "Mae | {CUSTOM1} | eisiau i chi ddod yn wirfoddolwr Credydau Amser Tempo",
  wantsYouToBecomeAdministrator:
    "Mae | {CUSTOM1} | eisiau i chi fod yn weinyddwr ar eu cyfrif Credydau Amser Tempo",
  confirm16OrOlder: "Rwyf yn cadarnhau fy mod yn 16 mlwydd oed neu'n hŷn",
  or: "Neu",
  noActivitiesFound: "Heb gael hyd i weithgareddau",
  iAmUnder16:
    "Rwyf yn iau nag 16 oed ac yn cadarnhau bod gennyf gydsyniad fy rhiant neu warcheidwad cyfreithiol i ymuno â Chredydau Amser Tempo",
  pleaseIndicateOver16:
    "Nodwch eich bod yn hŷn nag 16 oed neu fod gennych gydsyniad gan riant neu warcheidwad i gofrestru",
  tooShort: "Rhy fyr - dylai gynnwys {CUSTOM1} nod o leiaf",
  tooShort1: "Rhy fyr - dylai gynnwys 1 nod o leiaf",
  tooShort5: "Rhy fyr - dylai gynnwys 5 nod o leiaf",
  aboutYou: "Amdanoch Chi",
  atTempoExplanation:
    "Mae Tempo eisiau cyrraedd pobl o wahanol gefndiroedd a gwerthfawrogi cyfraniad pawb. Mae hyn yn golygu bod angen i ni gasglu gwybodaeth am bwy sy'n cofrestru i ddefnyddio Credydau Amser",
  informationUseExplanation:
    "Bydd y wybodaeth hon yn cael ei defnyddio'n ddienw i ddeall a yw Credydau Amser yn berthnasol ac yn hygyrch i wahanol grwpiau. Ni fyddwn byth yn gwerthu eich gwybodaeth bersonol i drydydd partïon",
  appreciateYourHelp:
    "Byddem yn gwerthfawrogi eich cymorth, ond gallwch hepgor y cam hwn isod. Diolch",
  whatGender: "Beth yw eich rhywedd",
  haveYouVolunteered: "A ydych chi wedi gwirfoddoli o'r blaen",
  continue: "Parhau",
  skipStep: "Hepgor y cam hwn",
  nowAMember: "Rydych chi nawr yn wirfoddolwr gyda Credydau Amser Tempo",
  findWaysToVolunteer: "Darganfod Sut Medrwch Chi Virfolddoli",
  searchForWaysExplanation:
    "Chwiliwch am ffyrdd i helpu grwpiau a gwasanaethau cymunedol wrth fynd at 'Ennill Credydau Amser Tempo'. I ddiolch i chi, ychwanegir Credydau Amser yn syth at eich cyfrif ar-lein",
  checkBalance: "Gwirio'ch Balans",
  youCanCheckTCExplanation:
    "Mi fedrwch wirio faint o Gredydau Amser sydd yn eich cyfrif a gweld lle cafwyd eu hennill a'u defnyddio wrth fynd i 'Gweld fy Hanes Credydau Amser'",
  enjoyFunActivities: "MWYNHAU GWEITHGAREDDAU DIFYR",
  searchForFunActivitiesExplanation:
    "Chwiliwch am weithgareddau difyr medrwch eu cyrchu drwy ddefnyddio Credydau Amser wrth fynd at 'Defnyddio eich Credydau Amser'. Defnyddiwch eich Rhif Adnabod yn y lleoliad, i logi lle o flaen llaw neu i dderbyn côd mynediad arlein",
  youCanEarnTCWith: "Gallwch bellach ennill Credydau Amser Tempo gyda",
  dontHaveTempoAccount: "Dim cyfrif Tempo",
  giftTC: "Rhoddi Credydau Amser Tempo",
  shareLove: "Rhannwch y Cariad",
  youCanGiftExplanation:
    "Gallwch roddi eich Credydau Amser Tempo i'ch ffrindiau a'ch teulu",
  myTCHistory: "Fy Hanes Credydau Amser Tempo",
  tempoTCHistory: "Hanes Credydau Amser Tempo",
  myTCBalance: "Fy malans Credydau Amser",
  organisationPerson: "Sefydliad / Unigolyn",
  youCanGiftExplanationLong:
    "Gallwch roddi eich Credydau Amser Tempo i'ch ffrindiau a'ch teulu. Byddant yn derbyn neges e-bost i gofrestru a chasglu eu Credydau Amser",
  whatEmail: "Beth yw eu cyfeiriad e-bost?",
  howManyTCsGift: "Faint o Gredydau Amser yr hoffech eu rhoddi",
  confirm: "Cadarnhau",
  sorryNotEnough: "Mae'n ddrwg gennym, nid oes gennych ddigon o Gredydau Amser",
  sorryNotEnoughInYourAccount:
    "Yn anffodus nid oes gennych ddigon o Gredydau Amser yn eich cyfrif",
  noTransactions: "Dim trafodion hyd yma",
  myAccount: "Fy Nghyfrif",
  changesSaved: "Newidiadau wedi'u harbed",
  contactTempoToDelete: "Cysylltwch â Tempo os hoffech ddileu eich cyfrif",
  termsOfUse: "Telerau defnyddio",
  privacy: "Preifatrwydd",
  cookies: "Cwcis",
  findWaysToGive: "Dod o hyd i ffyrdd o roi amser yn agos ataf i",
  selectCityTown: "Dewiswch ddinas / tref",
  searchByActivity: "Chwilio yn ôl gweithgaredd",
  searchByCause: "Chwilio yn ôl achos",
  next: "Nesaf",
  findFunActivitiesOnline: "Dod o Hyd i Weithgareddau Difyr Ar-lein",
  nearby: "Gerllaw",
  sortBy: "Trefnu yn ôl",
  popularity: "Poblogrwydd",
  nearest: "Agosaf",
  recentlyAdded: "Ychwanegwyd yn ddiweddar",
  relevance: "Relefans",
  cityTown: "Dinas / tref",
  searchByActivityType: "Chwilio yn ôl math o weithgaredd",
  activityType: "Math o weithgaredd",
  gifted: "Wedi rhoddi",
  transactionDate: "Dyddiad y Trafodiad",
  transactionDates: "Dyddiadau Trafodiad",
  activityDateSingle: "Dyddiad Gweithgaredd",
  activityDates: "Dyddiadau Gweithgaredd",
  home: "Hafan",
  earnTC: "Ennill Credydau Amser Tempo",
  earnTCShort: "Ennill Credydau Amser",
  spendTCShort: "Defnyddio Credydau Amser",
  giftTCShort: "Rhoddi Credydau Amser",
  typeFirstNameHere: "Teipiwch yr enw cyntaf yma",
  typeLastNameHere: "Teipiwch y cyfenw yma",
  selectAsManyLike: "Dewiswch gymaint â dymunwch",
  myTCHistoryShort: "Fy hanes Credydau Amser",
  howTCsWork: "Sut mae Credydau Amser yn Gweithio",
  accountSettings: "Gosodiadau Cyfrif",
  logOut: "Allgofnodi",
  dashboard: "Dangosfwrdd",
  acceptTC: "Derbyn Credydau Amser",
  earnSpendActivities: "Darganfod Cyfleoedd Credydau Amser",
  tcHistoryShort: "Hanes Credydau Amser",
  recruitVolunteers: "Recriwtio gwirfoddolwyr",
  insights: "Dealltwriaeth",
  invalidPasswprd: "Cyfrinair annilys",
  submit: "Danfon",
  greatSetUpPassword:
    "Gwych, rydych wedi gosod cyfrinair newydd. Cadwch ef yn ddiogel",
  enterEmailExplanation:
    "Rhowch y cyfeiriad e-bost sy'n gysylltiedig â'ch cyfrif a byddwn yn anfon dolen atoch i ailosod eich cyfrinair. Cofiwch edrych yn eich ffolder e-bost sothach",
  sendLink: "Anfon dolen",
  invalidEmail: "Cyfeiriad e-bost annilys",
  passwordResetLinkSent:
    "Mae dolen ailosod cyfrinair wedi cael ei hanfon atoch trwy neges e-bost",
  returnToLogIn: "Dychwelyd i fewngofnodi",
  tcAccepted: "CREDYDAU AMSER A DDERBYNIWYD",
  tcAcceptedDuringYear:
    "Credydau Amser yn cael eu derbyn yn ystod y flwyddyn galendar gyfredol",
  sinceYouJoinedTempo: "Ers i chi ymuno â Tempo",
  since: "Ers",
  uniqueTempoMemberVisits: "YMWELIADAU UNIGRYW GWIRFODDOLWR TEMPO",
  thisYear: "Eleni",
  tempoTCTransactions: "TRAFODION CREDYDAU AMSER TEMPO",
  newReturningVisitor: "Ymwelydd Newydd / sy'n Dychwelyd",
  new: "Newydd",
  returning: "Dychwelyd",
  exportActivity: "Gweithgaredd Allforio",
  cookieExplanation:
    "Mae'r wefan hon yn defnyddio cwcis i wella profiad y defnyddiwr",
  findOutMoreCookies: "Darganfyddwch fwy am ein Polisi Cwcis",
  iUnderstand: "Dwi'n deall",
  myBalance: "Fy malans",
  giftSent: "rhodd wedi ei ddanfon",
  congratulationsGiftReceived:
    "Llongyfarchiadau! Mae {CUSTOM1} newydd dderbyn eich rhodd",
  congratulationsEmailSent:
    "Llongyfarchiadau! Mae ebost wedi cae ei yrru iddynt gyda dolen i gofrestru a derbyn eu Credydau Amser Tempo",
  didntReceiveEmail: "Heb dderbyn ebost",
  copyLinkExplanation:
    "Gallwch chi gopïo a phastio'r ddolen isod mewn i e-bost, neges destun neu neges cyfryngau cymdeithasol",
  goToHomePage: "Ewch i'r hafan",
  receivedGift: "Rydych wedi derbyn rhodd",
  receivedTCsFromFriend:
    "Rydych wedi derbyn {CUSTOM1} o Gredydau Amser Tempo gan eich ffrind",
  welcomeBig: "Croeso!",
  logInSo: "Llogi mewn fel gall",
  canAddAsAdmin: "eich ychwanegu chi fel gweinyddwr eu grŵp",
  canAddYouToGroup: "eich ychwanegu i'r grŵp",
  acceptTCWithoutLogIn: "Derbyn Credydau Amser heb fewngofnodi",
  tcDelivery: "Danfoniad Credydau Amser",
  gift: "Rhoddi",
  of: "O",
  records: "gofnod(ion)",
  spend: "Defnyddio",
  used: "Defnyddiwyd",
  use: "Defnyddio",
  earn: "Ennill",
  earned: "Ennillwyd",
  transactionDetails: "Manylion y Trafodiad",
  person: "Unigolyn",
  dateOfActivity: "Dyddiad y gweithgaredd",
  to: "I",
  perPerson: "Fesul unigolyn",
  logInOnComputer:
    "Mewngofnodwch ar gyfrifiadur i weld eich hanes gweithrediadau cyfan",
  translateExport: "Cysylltwch â Tempo am gyfieithiad o'r allforio",
  reason: "Rheswm",
  selectVenue: "Dewsiwch eich lleoliad",
  venue: "Lleoliad",
  typeVenue: "Teipiwch y lleoliad",

  // Zendesk
  yourNameOptional: "Eich enw (opsiynol)",
  zenEmailAddress: "Cyfeiriad ebost",
  howCanWeHelpYou: "Sut allwn ni eich helpu chi?",
  attachments: "Atodiadau",
  addUpTo5Files: "Ychwanegwch hyd at 5 o ffeiliau",
  send: "Anfon",
  help: "Cymorth",
  translate: "Cyfieithu",
  // ERROR MESSAGES
  passwordMustContain:
    "Mae'n rhaid i'r cyfrinair gynnwys o leiaf 8 cymeriad, un priflythyren, un islythyren ac un rhif",
  invalidPhone: "Rhif ffôn annilys",
  invalidRole: "Rôl annilys",
  invalidUrl: "Url annilys",
  selectParticipantsNumber: "Dylech chi ddewis nifer y bobl",
  mustAgreeTC: "Mae'n rhaid i chi gytuno gyda'n telerau ac amodau",
  mustConfirmPermission: "Mae'n rhaid cadarnhau caniatad",
  tooLong100: "Rhy hir - dylai fod yn 100 cymeriad ar y mwyaf",
  tooLong500: "Rhy hir - dylai fod yn 500 cymeriad ar y mwyaf",
  tooLong50: "Rhy hir - dylai fod yn 50 cymeriad ar y mwyaf",
  tooLong7: "Rhy hir - dylai fod yn 7 cymeriad ar y mwyaf",
  invalidPostcode: "Côd Post annilys",
  membershipIDShouldBe: "Dylai eich Rhif Adnabod gynnwys o leiaf 8 cymeriad",
  shouldBeMoreZero: "Dylai fod mwy na sero",
  shouldBeValidType: "Dylai fod yn fath dilys",
  spendActivityCannotBeMadeLive:
    "Ni fedrir rhestri gweithgaredd fel un byw os bydd y dyddiad cau yn y gorffennol",
  shouldBeGreaterThan0OrEmpty:
    "Dyler fod yn fwy na 0, new dewisiwch gwag i glirio",
  oneAboveInvalidEmail: "Mae un o'r uchod yn ebost annilys",
  selectOneItem: "Dylech ddewis o leiaf un eitem",
  pageNotFound: "Tudalen heb ei darganfod",
  personDoesntHaveEnoughTC: "Nid oes gan y person yma ddigon o Gredydau Amser",
  notEnoughTimeCredits: "Nid oes gennych ddigon o Gredydau Amser",
  dataNotFound: "Methu cael hyd i'r data rydych wedi gofyn amdano",
  invalidCredentials: "Cymwysterau annilys",
  accessRouteLogin:
    "I gael mynediad i'r llwybr hwn mae angen i chi fewngofnodi yn gyntaf",
  noAccessPermission: "Nid oes gennych ganiatad mynediad",
  invalidEmailOrPassword: "Rydych wedi mewnbynnu ebost neu gyfrinair annilys",
  invalidPassword: "WELSH - You have entered an invalid password",
  missingParameters: "Paramedrau coll",
  validationError: "Gwall dilysu",
  invalidMembershipID: "Rhif Adnabod yn annilys",
  activityNotFound: "Gweithgaredd heb ei ddarganfod",
  sorrySomeoneSignedUp:
    "Mae'n ddrwg gennym, mae rhywun gyda'r un manylion eisioes wedi cofrestru",
  emailAlreadyExists:
    "Mae cyfrif defnyddiwr gyda'r cyferiad ebost yma wedi ei gofrestru yn barod",
  emailNotAllowedMultipleUsers:
    "Mae cyfrif defnyddiwr yn bodoli yn barod gyda'r ebost hwn. I gofrestru, defnyddiwch y cyfrinair sydd yn gysylltiedig â'r cyfrif hwn os gwelwch yn dda.",
  emailAlreadyHasEarnGroup:
    "Mae cyfrif gyda'r cyfeiriad ebost hwn mewn bodolaeth yn barod. Os rydych yn ceisio ychwanegu grŵp newydd i'ch cyfrif, mewnbynnwch y cyfeiriad ebost a'r cyfrinair ar gyfer y cyfrif hwnnw uchod neu | cliciwch yma i fewngofnodi",
  userWithEmailExists:
    "Mae defnyddiwr gyda'r cyferiad ebost yma yn bodoli eisioes. Defnyddiwch gyfeiriad ebost arall",
  emailAddressTaken:
    "Mae'r cyfeiriad ebost yma yn cael ei ddefnyddio yn barod, mae angen i chi ddefnyddio ebost unigryw i reoli'r grŵp yma",
  spendVenueAlreadyCreated:
    "Mae lleoliad wedi ei gofrestru eisioes gyda'r cyfeiriad ebost hwn",
  emailNotFound: "Cyfeiriad ebost heb ei ddarganfod",
  linkExpired: "Mae eich dolen wedi mynd heibio neu'n annilys",
  requestProcessed: "Mae'r cais yma wedi ei brosesu",
  earnCanOnlyAcceptFromProgramme:
    "Ni all y grwp dderbyn ddim ond oddi wrth y rhaglen y mae wedi ei benodi iddo",
  exceededProgrammeBudget: "Rydych chi wedi rhagori ar gyllideb y rhaglen",
  giftNoLongerAvailable: "Nid yw'r anrheg yma ar gael mwyach",
  inviteLinkExpired: "Mae'r dolen gwahoddiad yma wedi dod i ben",
  cannotAcceptOwnGifts: "Ni allwch dderbyn eich rhoddion eich hun",
  invalidProgrammeInviteLink: "Dolen gwahoddiad rhaglen annilys",
  notEnoughBookingCodes: "Does dim digon o godau archebu ar gael",
  noOnlineCodes: "Nid oes gan y weithgaredd yma godau ar-lein",
  reachedCodesLimitL: "Rydych wedi cyrraedd terfyn y codau",
  priceDoesntMatch: "Nid yw'r pris yn cyfateb",
  staffAlreadyExists:
    "Mae cyfrif defnyddiwr staff gyda'r un ebost yn barod yn bodoli",
  emailDoesntMatchInvite:
    "Nid yw eich cyfeiriad ebost yn cyfateb i'r un yn y gwahoddiad yma",
  adminLimitReached:
    "Mae'r uchafswm o weinyddwyr eisioes wedi ei gyrraedd. Gofynnwch i'r Grŵp Gwirfoddol ddileu defnyddiwr arall er mwyn i chi gofrestru.",
  notAssignedToEarnGroup: "Nid ydych chi wedi eich aseinio i unrhyw grwp",
  onlyManageOneEarnGroup:
    "Yn anffodus, gallwch ddim ond reoli un grŵp ar yr un pryd",
  emailTakenNeedUnique:
    "Mae'r cyferiaid ebost yma yn barod yn cael ei ddefnyddio, mae angen i chi ychwanegu cyfeiriad ebost unigryw i'ch galluogi i reoli'r rhaglen yma",
  onlyManageOneProgramme:
    "Yn anffodus, gallwch ddim ond rheoli un rhaglen ar y tro",
  userAlreadyAddedToProgramme:
    "Mae'r defnyddiwr yma wedi ei ychwanegu i'r rhaglen yn barod",
  userAlreadyManagingProgramme:
    "Mae'r defnyddiwr yma yn rheoli rhaglenni eraill yn barod",
  extProgManagerLimitReached:
    "Mae'r uchafswm o Reolwyr Rhaglen Allanol wedi ei gyraedd ar gyfer y rhaglen yma. Os gwelwch yn dda cysylltwch â Tempo i ddileu un er mwyn eich galluogi i gofrestru",
  notAssignedToProgramme: "Nid ydych wedi eich aseinio i unrhyw raglen",
  notEnoughTCsInBudget:
    "Does dim digon o Gredydau Amser yn y cyllideb. Mae {CUSTOM1} o Gredydau Amser yn weddill. Os oes angen mwy arnoch, cysylltwch gydag aelod o staff Tempo",
  internalServerError: "Gwall gweinydd mewnol",
  tooManyRequests: "Gormod o geisiadau",
  shouldProvideAuthorizationName: "Dyler darparu enw awdurdodol",
  restrictionPerVolunteerHasReached: "Uchafswm y pen wedi ei gyrraedd",
  thisEmailIsLinked: "Mae'r e-bost hwn yn gysylltiedig â chyfrif arall",
  enterPasswordToSwitch: "Rhowch y cyfrinair i {CUSTOM1} ei newid",
  yourNameWillBeUpdated:
    "Bydd eich enw'n cael ei ddiweddaru i'r cyfrif newydd ({CUSTOM1})",
  note: "Nodyn",
  done: "Wedi ei wneud",
  error: "gwall",
  complete: "wedi ei gwblhau",
  processed: "wedi ei brosesu",
  badRequest: "cais wael",
  unauthorized: "anawdurdodedig",
  forbidden: "gwaharddedig",
  requestTimeout: "Cais wedi amseru allan",
  conflict: "gwrthdrawiad",
  badGateway: "porth gwael",
  serviceUnavailable: "gwasanaeth ddim ar gael",
  gatewayTimeout: "terfyn amser porth",
  notImplemented: "heb ei weithredu",
  paymentRequired: "Mae angen taliad",
  notAcceptable: "Annerbyniol",
  methodNotAllowed: "Ni chaniateir y dull yma",
  proxyRequired: "Angen dilysu dirprwy",
  gone: "Wedi mynd",
  lengthRequired: "Hyd ei angen",
  preconditionFailed: "Rhag-amod wedi methu",
  requestTooLarge: "Gofyn am endid rhy fawr",
  unsupportedMediaType: "Math o gyfrwng heb ei genfnogi",
  requestRange: "Yr amrediad a ofynnir am ddim yn bodloni",
  expectactionFailder: "Methodd y disgwyliad",
  teapot: "Tebot ydw i",
  unprocessableEntity: "Endid na ellir ei brosesu",
  locked: "Wedi cloi",
  failedDependency: "Dibyniaeth wedi methu",
  tooEarly: "Rhy fuan",
  upgradeRequired: "Angen Uwchraddio",
  preconditionRequired: "Angen Rhag-amod",
  requestHeaderTooLarge: "Pennawdau Rhy Fawr",
  unavailableLegal: "Ddim ar gael oherwydd rhesymau cyfreithiol",
  httpNotSupported: "Fersiwn HTTP ddim wedi ei gefnogi",
  variantNegotiates: "Yr amrywiol hefyd yn negodi",
  insufficientSTorage: "Storfa annigonol",
  bandwidthExceeded: "Wedi cyrraedd eithaf lled band",
  notExtended: "Heb ei ymestyn",
  networkRequired: "Mae angen dilysu eich rhwydwaith",
  searchByPostcode: "Chwilio fesul côd post",
  searchByKeyword: "Chwilio yn ôl allweddair",
  mostPopular: "Mwyaf poblogaidd",
  activitiesMatchLocation:
    "Gweithgareddau sy'n cyd-fynd â'ch chwiliad yn agos i",
  noActivitiesFoundSorry: "Yn anffodus, heb gael hyd i weithgareddau",
  findActivitiesNearMe: "DARGANFOD GWEITHGAREDDAU HWYL GERLLAW",
  noVersionInLanguage:
    "Mae'n ddrwg gennym, nid oes fersiwn o'r rhestriad yma yn eich iaith ddewisiol",
  errorCopying: "Mae'n ddrwg gennym, bu gwall wrth gopïo",
  recruitVolunteersPageHeader:
    "RHOWCH WYBOD I BOBL Y GALLANT ENNILL CREDYDAU AMSER GYDA CHI!",
  provideDetailsAboutOrganisation:
    "Rhowch fanylion am eich sefydliad a'r mathau o weithgareddau gwirfoddoli rydych chi'n eu cynnal fel bod darpar wirfoddolwyr yn gallu dod o hyd i chi a chysylltu",
  english: "Saesneg",
  welsh: "Cymraeg",
  earnKeywordsExamples: "Esiampl: bwyd, gwastraff bwyd, coginio, cogydd, cinio",
  earnKeywordsLabel:
    "Mewnbynnwch unrhyw eiriau allweddol i gynorthwyo wrth chwilio, wedi'u gwahanu gan atalnod",
  fileMaxSize: `uchafswm maint ffeil {CUSTOM1}mb`,
  addAnActivity: "Ychwanegu gweithgaredd",
  addWelshVersion: "Ychwanegwch fersiwn Gymraeg",
  onlySignUpIfInvitedEGAdmin:
    "Yn anffodus, gallwch ond gofrestru os wedi eich gwahodd gan grŵp. Os rydych yn helpu i reoli grŵp, gofynnwch iddynt eich ychwanegu fel gweinyddwr",
  selectActivityType: "Dewis math o weithgaredd",
  doYouHavePhysicalConditions:
    "A oes gennych unrhyw gyflyrau neu salwch corfforol neu iechyd meddwl sy'n para neu y disgwylir iddynt bara 12 mis neu fwy?",
  select: "Dewiswch",
  tickAsManyAsApply: "Ticiwch gynifer â sydd yn berthnasol",
  WhatIsYourEthnic: "Beth yw eich grŵp ethnig?",
  all: "Pob un",
  pickUp3Options: "Gallwch ddewis dim ond hyd at 3 opsiwn",
  selectActivity: "Dewis gweithgaredd",
  selectCause: "Dewis achos",
  somethingWentWrong: "Aeth rhywbeth o'i le",
  longitudeLatitudeError:
    "Gwall wrth gael y lledred a'r hydred ar gyfer côd post a gyflwynwyd:",
  postcodeNotRecognised: "Ni chydnabyddir un o'r codau post a gofnodwyd",
  inputFieldMissing:
    "Nid yw o leiaf un o'r meysydd mewnbwn wedi'i lenwi neu fe gofnodwyd y manylion yn anghywir. Gwiriwch y ffurflen uchod am ragor o fanylion",
  ifYouBuildIt: "Adeiladwch ef, ac fe ddown...",
  buildingThisPart:
    "Mae ein tîm yn gweithio'n galed i adeiladu'r rhan newydd hon o'r platfform. Mi fydd yma cyn bo hir!",
  shallNotPass: "Ni chewch fynd heibio!",
  doNotHavePermission:
    "Yn anffodus, nid oes gennych ganiatâd i weld y dudalen honno",
  bitLost: "Wps, rydw i ar goll!",
  sorryPageDoesntExist:
    "Yn anffodus, nid yw'r dudalen rydych chi'n chwilio amdani yn bodoli",
  tooManyRequestsTryAfter: "Gormod o geisidau, ceisiwch eto yn nes ymlaen",
  goodNews: "Newyddion da",
  switchAccount: "newid cyfrif",
  venueInsights: "Dealltwriaeth lleoliad",
  offers: "Cynnigion",
  venues: "Lleoliadau",
  membershipIdExplainer:
    "Mi fydd angen hwn arnoch pan fyddwch yn denyddio eich Credydau Amser yn y lleoliad neu yn llogi lle o flean llaw. Os hoffech i ni bostio cerdyn Rhif Adnabod i chi, danfonwch eich cyfeiriad atom drwy ddefnyddio'r botwm Help.",
  setupMoreVenues:
    "Os hoffech sefydlu mengofnodiad ar wahan ar gyfer mwy nag un lleoliad, cysylltwch â ni drwy ddefnyddio'r botwm Help neu drwy ffonio 020 8245 3099,  Llun-Gwener 9yb-5yp.",
  spendPartnerAcceptTermsBody:
    "Rydym wedi sylwi taw hwn yw eich tro cyntaf yma felly rydym am rannu ein | Termau defnyddio | a'n |  Polisi Preifatrwydd | gyda chi. Cliciwch Parhau i'w derbyn",
  chooseYourVenue: "Dewisiwch EICH Lleoliad",
  selectYourAccount: "Dewisiwch eich cyfrif",
  selectAccount: "Dewis cyfrif",
  account: "Cyfrif",
  liveActivities: "Gweithgareddau byw",
  draftActivities: "Gweithgareddau drafft",
  viewVenuePage: "Gweld tudalen y lleoliad",
  status: "Statws",
  view: "Gweld",
  live: "Yn fyw",
  draft: "Drafft",
  issued: "Rhoddwyd",
  needHelpWithTranslation:
    "Angen cymorth gyda cyfieithu? Cysylltwch â Tempo gan ddefnyddio'r botwm help",
  createEnglishVersionOfProfile:
    "Mae angen i chi greu fersiwn Saesneg o'ch proffil. Os byddwch angen cymorth i wneud hyn cysylltwch â Tempo gan ddefnyddio'r botwm cymorth",
  adminSuccessfullyRemoved: "Gweinyddwr dileu llwyddiannus",
  adminSuccessChangeMainAccount: "Prif berchennog y cyfrif wedi ei ddiweddaru",
  noRecordsToDisplay: "Dim canlyniadau i'w dangos",
  activityNotLive:
    "Ar hyn o bryd nid oes unrhyw opsiynau i ddefnyddio Credydau Amser ar gyfer y gweithgaredd hwn. Dewch yn ôl yn fuan",

  unavailable: "Heb fod ar gael",
  restriction: "Cyfyngiadau",
  currentlyUnavailable: "Heb fod ar gael ar hyn o bryd",

  // [capacityReached, perMonthReached, perVolunteerReached, perSlotReached, perSlotReached, perTransactionReached]
  // are used in the code and the keys are generated dynamically
  // Don't delete please
  capacityReached:
    "Mae'n ddrwg gennym fod cyfanswm nifer y gweithgaredd hwn wedi'i gyrraedd",
  perMonthReached:
    "Mae'n ddrwg gennym fod cyfanswm nifer ar gyfer y mis hwn wedi'i gyrraedd",
  perVolunteerPerMonthReached:
    "Mae'n ddrwg gennym fod cyfanswm y nifer fesul gwirfoddolwr ar gyfer y mis hwn wedi'i gyrraedd",
  perWeekReached:
    "Mae'n ddrwg gennym fod cyfanswm y nifer ar gyfer yr wythnos hon wedi'i gyrraedd",
  perVolunteerPerWeekReached:
    "Mae'n ddrwg gennym fod cyfanswm y nifer fesul gwirfoddolwr ar gyfer yr wythnos hon wedi'i gyrraedd",
  perVolunteerReached:
    "Mae'n ddrwg gennym fod cyfanswm nifer y gwirfoddolwyr wedi'i gyrraedd",
  perSlotReached:
    "Mae'n ddrwg gennym fod cyfanswm nifer ar gyfer y slot hwn wedi'i gyrraedd",
  perTransactionReached:
    "Mae'n ddrwg gennym fod cyfanswm nifer ar gyfer nifer y bobl a gyrhaeddwyd",
  restrictionExists: "Ychydig o argaeledd sydd ar gael i'r cynnig hwn",
  perMonth: "fesul mis",
  perVolunteerPerMonth: "fesul gwirfoddolwr y mis",
  perWeek: "fesul wythnos",
  perVolunteerPerWeek: "fesul gwirfoddolwr yr wythnos",
  perVolunteer: "fesul gwirfoddolwr",
  perSlot: "fesul slot",
  perTransaction: "fesul nifer y bobl",
  toProceed: "I barhau nodwch eich enw cyn clicio Cadarnhau",
  proceedingTransactionWithError:
    "Bydd bwrw ymlaen â'r trafodiad hwn yn mynd â chi dros gyfanswm y dyraniad a ganiateir i'r gwirfoddolwr hwn",
  searchWithinArea: "Chwilio o fewn yr ardal (milltiroedd)",
  nationalOffers: "Cynigion cenedlaethol",
  includeAllNationalOffers: "Cynnwys yr holl gynigion cenedlaethol",
  availability: "Argaeledd",
  onlyShowOffersCurrentlyAvailable:
    "Ond dangos cynigion sydd ar gael ar hyn o bryd",
  clearFilters: "Clirio hidlwyr",
  searchIn: "Chwilio yn",
  addOffer: "Ychwanegu cynnig",
  changeSubmitted: "Newidiadau wedi eu cyflwyno",
  yourActivityIsCurrentlyBeingReviewed:
    "Caiff eich gweithgaredd ei adolygu gan Tempo. Cewch eich hysbysu mor fuan ag y byddwn wedi ei gymeradwyo a’i fod yn fyw.",
  activityCreated: "Gweithgaredd wedi ei greu",
  viewManageOffers: "Gweld a rheoli cynigion",
  edit: "Golygu",
  earnGroupOffersNote:
    "Yma gallwch greu gweithgareddau i dderbyn Credydau Amser, yn amodol ar gymeradwyaeth Tempo. Os yr ydych am wirio pa mor debygol ydyw y cewch eich cymeradwyo, ffonwich 02920 566 132",
  whoWouldYouLikeToSignUpAs: "Fel pwy yr hoffech chi gofrestru?",
  draftActivityCreatedMessage:
    "Mae eich gweithgaredd wedi ei greu. Cliciwch i’w wneud yn fyw pan rydych yn barod iddo gael ei adolygu gan Tempo a’i gyhoeddi.",
  volunteer: "Gwirfoddolwr",
  youWouldLikeToOrAlreadyVolunteer:
    "Hoffech neu yr ydych eisoes yn gwirfoddoli ac yn ennill Credydau Amser Tempo",
  youWouldYouWouldLikeToSupport:
    "Hoffech ddefnyddio Credydau Amser Tempo i gefnogi eich lleoliadau neu grwpiau gwirfoddoli",
  county: "Sir",
  typeCounty: "Teipio sir...",
  searchByLocation: "Chwilio fesul lleoliad",
  searchByCityTown: "Chwilio fesul dinas/neu dref",
  searchByCounty: "Chwilio fesul sir",
  proceedingWithTransaction:
    "Bydd bwrw ymlaen â'r trafodiad hwn yn mynd â chi dros y nifer rydych chi wedi'i ddyrannu i'r gweithgaredd hwn",
  areYouSure: "Ydych chi’n siwr?",
  comingSoon: "Yn dod cyn hir",
  thisActivityWillNeedToBeReApproved:
    "Bydd angen ail-gymeradwyo'r gweithgaredd hwn felly os mai dim ond mân newid yr ydych yn ei wneud, cysylltwch â'r tîm fesul y botwm Cymorth ar y dudalen.",
  activitySaved: "Gweithgaredd wedi ei gadw",
  bookingCodesError: "Gall pob cod archebu cael 50 cymeriad ar y mwyaf",
  viewActivity: "Gweld gweithgaredd",
  activityFormStepError:
    "Nid oes modd i’r cynnig hwn fynd yn fyw hyd nes bod yr holl gamau wedi eu cwblhau",
  basicInformation: "Gwybodaeth sylfaenol",
  provideActivityDetails:
    "Rhowch y manylion a fydd yn ymddangos ar eu Rhestr Gweithgareddau",
  isOnlineActivity: "A yw hwn yn weithgaredd ar-lein?",
  howManyPersonPerTC: "Sawl Credyd Amser y person?",
  makeActivityLive: "Gwneud y gweithgaredd yn fyw",
  makeActivityFeatured: "Marcio'r gweithgaredd fel Nodweddiol",
  nextStep: "Cam nesaf",
  whenUseTC: "Pryd fedrwch ddefnyddio Credydau Amser?",
  provideActivityDates:
    "A wnewch chi ddarparu’r dyddiadau a'r amserau pan fedrwch ddefnyddio Credydau Amser ar gyfer y gweithgaredd hwn.",
  useTcAnyTime: "Gallwch ddefnyddio Credydau Amser ar unrhyw adeg",
  addAnotherDate: "Ychwanegu dyddiad arall",
  whenActivityEnd: "Pryd mae’r gweithgaredd yn dod i ben?",
  whenActivityEndSubtitle:
    "Dewisol, defnyddiwch os oes gan unrhyw amser dyddiad gorffen?",
  typeNumber: "Teipio rhif yma",
  totalCapacityLabel: "Cyfanswm nifer",
  totalCapacityPerMonthLabel: "Cyfanswm nifer fesul mis",
  totalCapacityPerVolunteerPerMonthLabel:
    "Cyfanswm nifer fesul gwirfoddolwr fesul mis",
  totalCapacityPerWeekLabel: "Cyfanswm nifer fesul wythnos",
  totalCapacityPerVolunteerPerWeekLabel:
    "Cyfanswm nifer fesul gwirfoddolwr fesul wythnos",
  totalCapacityPerVolunteerLabel: "Cyfanswm nifer fesul gwirfoddolwr",
  totalCapacityPerSlotLabel: "Cyfanswm nifer fesul slot",
  totalCapacityPerTransactionLabel: "Uchafswm y bobl fesul trafodiad",
  createActivity: "Creu gweithgaredd",
  WhatTheyNeedToKnow: "Beth sydd angen iddynt wybod?",
  WhatTheyNeedToKnowMoreInfo:
    "Rhowch wybodaeth bellach y bydd angen i bobl ei wybod.",
  isBookingRequiredForGroups: "A oes angen i grwpiau archebu o flaen llaw?",
  maxGroupSizeLabel: "Uchafswm maint y grŵp",
  howToUseTc: "Sut mae defnyddio Credydau Amser",
  howToUseTcDetails:
    "Rhowch fanylion am y ffyrdd y gallwch ddefnyddio Credydau Amser ar y gweithgaredd hwn.",
  enterBookingCodes:
    "Rhowch eich codau archebu ar gyfer y gweithgaredd hwn, pob un wedi'i wahanu gan atalnod",

  codesDetected: "{CUSTOM1} codau wedi'u canfod",
  codesStatus:
    "{CUSTOM1} codau byw yn weddill ({CUSTOM2} codau eisoes wedi'u defnyddio)",
  useCodesLink: "Dolen i ddefnyddio codau",
  useCodesLinkHelper:
    "os caiff ei gynnwys, bydd hwn yn dangos fel botwm  pan fydd y gwirfoddolwr yn derbyn ei godau yn dweud 'Ewch i wefan y partner'",
  activityNamePlaceholder: "e.e Dosbarth Ffitrwydd",
  activitySubtitle: "Is-deitl y Gweithgaredd",
  activitySubtitlePlaceholder: "e.e. Ioga / Pilates / BodyPump",
  selectActivitiesTypes: "Dewis Math(au) o Weithgaredd",
  aboutActivity: "Gwybodaeth am y Gweithgaredd",
  typeWebsiteUrl: "Teipiwch url y wefan",
  activityExternalLink: "Dolen allanol y gweithgaredd",
  activityImageHelper:
    "isafswm dimensiynau 820 x 280, maint ffeil ar y mwyaf 2mb",
  keywordsPlaceholder:
    "Esiampl: Campfa, Ymarfer Corff, Chwaraeon, Sesiwn Ffitrwydd",
  keywordsLabel:
    "Nodwch y geiriau allweddol i helpu gyda chwiliad, wedi eu gwahanu gan atalnod",
  repeatsMonthly: "Yn ailadrodd yn fisol ar",
  selectDates: "Dewis dyddiadau",
  selectTimes: "Dewis amserau",
  restrictionsOnDates: "Unrhyw gyfyngiadau ar y dyddiau yma?",
  restrictionsOnDatesPlaceholderHelper:
    "e.e. nid yw ar gael yn ystod hanner tymor – uchafswm 500 cymeriad",
  yes: "Ydy",
  no: "Nac ydy",
  addAnyFurtherInfo: "Ychwanegu gwybodaeth bellach",
  furtherInfoHelper: "e.e. Ewch i giât 1, Rhaid bod yn 18 oed neu'n hŷn",
  bookingCodesDetails:
    "Rhowch ragor o fanylion ar sut maen nhw'n defnyddio eu codau archebu",
  step1: "Gwyboodaeth Sylfaenol",
  step2: "Pryd fedrwch ddefnyddio Credydau Amser?",
  step3: "Beth sydd angen iddynt wybod?",
  step4: "Sut mae defnyddio Credydau Amser",
  startTime: "Amser Dechrau",
  endTime: "Amser Gorffen",
  underReview: "Yn cael ei adolygu",
  selectLocationType: "Cynnig math o leoliad",
  clickToUpload: "Clicio i uwchlwytho",
  dragImageHereOrClickToUpload:
    "Llusgwch ddelwedd yma neu cliciwch i uwchlwytho",
  repeatTransactions: "Ailadrodd trafodiad blaenorol?",
  repeatTransactionsHelper:
    "Os yw hwn yn drafodiad rheolaidd rydych chi wedi'i wneud o'r blaen, | cliciwch yma | i ddewis o'ch trafodion diweddar.",
  rememberToCheck: "Cofiwch wirio eich rhestr o wirfoddolwyr",
  rememberToCheckDescription:
    "Gallwn weld eich bod yn ailadrodd trafodiad blaenorol. Gwiriwch eich gwirfoddolwyr ddwywaith er mwyn sicrhau eich bod wedi cynnwys yr holl bobl iawn ac wedi rhoi'r Credydau Amser cywir iddynt!",
  noPrevTransactions: "Ni ddarganfuwyd unrhyw drafodion blaenorol",
  clickHere: "Clicio yma",
  invite: "Gwahodd",
  thisIsAnInviteToJoinProgrammeAddEarnGroup:
    "Dyma wahoddiad i ymuno â'r Rhaglen [PROGRAMME_NAME]. I dderbyn y gwahoddiad, dewiswch a ydych chi am ychwanegu grŵp sydd eisoes yn bodoli i'r rhaglen hon neu greu un newydd. Os ydych chi am ddarganfod mwy, ffoniwch 02920 566 132.",
  selectExistingGroup: "Dewis grŵp presennol",
  createNewGroup: "Creu grwp newydd",
  toIgnoreAndSkipThis: "I anwybyddu a hepgor hyn",
  sitTight: "Byddwch barod!",
  emailSentToSupportBeInTouch:
    "Mae e-bost wedi'i anfon i gefnogi pwy fydd mewn cysylltiad i drafod",
  myGroups: "Fy ngrwpiau",
  groupsIntro:
    "Yma gallwch weld yr holl grwpiau rydych chi wedi cofrestru i wirfoddoli gyda nhw ar hyn o bryd",
  confirmPermission:
    "Rwy'n cadarnhau bod gen i ganiatâd yr unigolyn rydw i'n ei gofrestru",
  and: "a",
  shouldBeMoreThanZero: "dylai'r rhif fod yn fwy na 0",
  shouldBeGreaterThanMinValue: "Dylai fod yn fwy na'r isafwerth",
  buyTempoTc: "Am gael swm gwahanol",
  wantDifferentAmount: "welsh - Want a different amount",
  generateInvoice: "Cynhyrchu Anfoneb a Chwblhau Taliad",
  pricePlaceholder: "Teipio rhif, isafswm {CUSTOM1} credydau...",
  orderConfirmation: "cadarnhad archeb",
  numberOfTimeCreditsAndCost: "Nifer y credydau Amser a'r gost",
  subtotal: "Is-gyfanswm",
  amountDue: "Swm sy'n ddyledus",
  totalVAT: "Cyfanswm TAW",
  payByCard: "Talu gyda cherdyn",
  cardNumber: "Rhif credyn",
  expiryDate: "Dyddiad dod i ben",
  CVV: "CVV",
  billingAddress: "Cyfeiriad bilio",
  pay: "Talu",
  purchased: "Wedi’i brynu",
  cancelled: "Wedi'i ganslo",
  paymentDue: "Taliad sy'n ddyledus",
  memberName: "Enw aelod",
  givenOutBy: "Credydau Amser wedi eu rhoi gan",
  amountPaid: "Swm a dalwyd",
  download: "Lawr lwytho",
  downloadInvoice: "Lawr lwytho infois",
  cancellationReason: "Rheswm dros ganslo",
  agreeToTermsAndConditions: "Cytunaf i’r | Termau ac Amodau",
  payByBankTransfer: "Am dalu trwy drosglwyddiad banc?",
  contactUsUsingHelpButton:
    "Cysylltwch â ni gan ddefnyddio'r botwm Help neu ffoniwch 02920 566132",
  needToCancelOrder:
    "Os oes angen i chi ganslo neu wneud newidiadau i'r archeb hon, cysylltwch â ni gan ddefnyddio'r botwm Help neu ffoniwch 02920 566132",
  confirmPaymentError:
    "Yn anffodus nid oedd modd prosesu’r taliad. Ni chodwyd tâl ar eich cerdyn.",
  tryAgain: "Ceisio eto",
  paymentUnsuccessful: "taliad yn aflwyddiannus",
  paymentSuccessful: "taliad yn llwyddiannus",
  paymentSuccessfulMessage:
    "Newyddion gwych, cymerwyd eich taliad yn llwyddiannus a bydd eich Credydau Amser nawr yn cael eu rhoi i chi. Gwiriwch eich blwch derbyn am e-bost cadarnhau gyda'ch derbynneb ynghlwm.",
  vatInvoice: "Anfoneb TAW",
  toSaveThisInvoiceClickPrint:
    "I gadw'r anfoneb hwn yn sâff, cliciwch ar Print yn eich porwr a dewiswch 'Cadw fel PDF'",
  proformaInvoice: "Anfoneb Profforma",
  invoiceNumber: "Rhif anfoneb",
  invoiceDate: "Dyddiad anfoneb",
  description: "Disgrifiad",
  quantity: "Maint",
  amount: "Swm",
  GBP: "GBP",
  totalVAT20: "Cyfanswm TAW 20%",
  timeCreditsPurchase: "Prynu Credydau Amser",
  tempoTimeCreditsPurchase: "Prynu Credydau Amser Tempo",
  clickToBookOnPartnerWebsite: "Cliciwch yma i logi lle ar wefan ein partner",
  copyLinkIntoBrowser: "Neu copiwch y ddolen i'ch porwr",
  transactionId: "Trafodiad ID",
  nearlyThere: "Bron yno",
  toActivateYourAccountPleaseGoToYourInbox:
    "I actifadu eich cyfrif ewch i'ch mewnflwch a chliciwch ar y ddolen cadarnhau yn yr e-bost yr ydym wedi'i anfon atoch.",
  sendAgain: "Anfon eto",
  emailSent: "E-bost wedi'i anfon",
};
