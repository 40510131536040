import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import getListsIntersectionValues from "lodash.intersection";
import Divider from "../../../components/Divider";

import { getEarnGroups } from "../../../redux/actions/earnGroups";
import { getLocation } from "../../../redux/actions/location";

import {
  focusDropDownEnglish,
  focusDropDownWelsh,
  focusValuesMapWelsh,
  earnActivityTypesDropDownEnglish,
  earnActivityTypesDropDownWelsh,
} from "../../../constants/dropdDownData";
import { GENERAL } from "../../../constants/navRoutes";

import * as S from "./style";

import * as T from "../../../components/Typograpy";
import EarnSpendOpportunity from "../../../components/Cards/EarnSpendOpportunity";
import Loading from "../../../components/Loading";
import { Row, Col } from "../../../components/Grid";
import { TabsSmall } from "../../../components/Tabs";
import { ClickableTag } from "../../../components/Tags";
import { Dropdown, InputField, AutoComplete } from "../../../components/Inputs";
import Button from "../../../components/Button";

import arrayOfObjectsFilter from "../../../utils/helpers/filters";
import sortByLocation from "../../../utils/helpers/sortByLocation";

import t from "../../../utils/helpers/translator";

const topTabs = ["searchByActivityType", "searchByCause"];
const sortByTabs = ["popularity", "recentlyAdded", "nearest", "relevance"];
const initialState = {
  postcode: "",
  location: "",
  keyword: "",
  activity: [],
  cause: "",
  page: 1,
  tab: topTabs[0],
  sortBy: sortByTabs[0],
  distance: 0,
  county: "",
  tracker: 1, // this is to get new results when clearing filters
};

const getQuery = (searchParams) => {
  const currentState = { ...initialState };

  Object.keys(initialState).forEach((key) => {
    if (searchParams.get(key)) {
      const currentValue = searchParams.get(key);
      currentState[key] = currentValue;
    }
  });
  return currentState;
};

const generateQuery = (obj, searchParams) => {
  Object.keys(obj).map((key) => searchParams.set(key, obj[key]));
  const result = searchParams.toString();
  return result;
};

const Earn = ({
  earnGroups = [],
  getEarnGroupsAction,
  loading,
  getLocationAction,
  language,
  citiesList,
  countiesList,
  homonymousStatesList,
  searchParams,
  initialState: initialStateWithSearchPrams,
  userPostcode,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [filteredGroups, setFilteredGroups] = useState([]);
  const [filtering, setFiltering] = useState(false);
  const [searchData, setSearchData] = useState(initialStateWithSearchPrams);
  const [availableActivitiesTypesArr, setAvailableActivitiesTypesArr] =
    useState([]);

  const firstUpdate = useRef(true);
  const cardsSectionRef = useRef();

  const countyFilter = (groups, countyName) => {
    const filtered = groups.filter((group) =>
      group.county?.includes(countyName || searchData.county),
    );
    return filtered;
  };

  const locationFilter = async (groups) => {
    const locationSearchKeyword = searchData.location || searchData.county;
    if (!searchData.postcode && !locationSearchKeyword) return groups;

    let data = [];
    let filteredCountries = [];

    if (!searchData.postcode) {
      if (
        !homonymousStatesList.includes(locationSearchKeyword) &&
        !searchData.county
      ) {
        filteredCountries = [];
      } else {
        filteredCountries = countyFilter(groups, locationSearchKeyword);
      }
      data = await getLocationAction({
        query: (searchData.location || searchData.county)
          ?.replace(/[^a-zA-Z ]/g, "")
          ?.split(" ")?.[0],
        queryType: searchData.county ? "county" : "city",
      });
    } else {
      data = await getLocationAction({
        query: searchData.postcode,
        queryType: "postcode",
      });
    }

    const { result } = data;

    const filtered = sortByLocation({
      locationArr: result,
      listings: groups,
      distanceRange: searchData.distance,
      sortByDistance: searchData.sortBy === "nearest",
    });

    return filtered.concat(filteredCountries);
  };

  const handleFilter = async ({
    searchResults = earnGroups,
    activity = searchData.activity,
    cause = searchData.cause,
  }) => {
    setFiltering(true);
    let updatedGroups = arrayOfObjectsFilter(
      searchResults,
      cause,
      "focusFields",
    );

    updatedGroups = arrayOfObjectsFilter(
      updatedGroups,
      activity,
      "publicActivities",
    );

    updatedGroups = await locationFilter(updatedGroups);

    setFilteredGroups(updatedGroups);
    setFiltering(false);
  };

  const handleSearch = async () => {
    const searchResults = await getEarnGroupsAction({
      isPublic: true,
      keyword: getQuery(searchParams).keyword,
      language,
      county: searchData.county,
      location: searchData.location,
      postcode: searchData.postcode,
      sortBy: searchData.sortBy,
    });

    handleFilter({ searchResults });
  };

  const handleSearchForm = (e) => {
    e.preventDefault();
    setSearchData((_data) => ({ ..._data, page: 1 }));
    handleSearch();
  };

  const handleTab = (tabText) => {
    setSearchData((_data) => ({
      ..._data,
      activity: [],
      cause: "",
      tab: tabText,
      page: 1,
      sortBy: "popularity",
    }));

    setFiltering(true);
  };

  const handleSortTab = (tabText) => {
    let postcode = searchData.postcode;
    let distance = searchData.distance;
    if (tabText === "nearest" && !postcode) {
      postcode = userPostcode;
      distance = 50;
    }
    setSearchData((_data) => ({
      ..._data,
      postcode,
      distance,
      sortBy: tabText,
      page: 1,
    }));
  };

  const handleActivity = (selected) => {
    if (!selected) return;
    const { value } = selected;
    if (!value) return;
    const updatedSelectedActivitiesObj = {};
    const selectedActivitiesNames = value.map(({ label }) => {
      updatedSelectedActivitiesObj[label] = label;
      return label;
    });
    setSearchData((_data) => ({
      ..._data,
      activity: selectedActivitiesNames,
    }));
  };

  const handleCause = (selected) => {
    setSearchData(({ cause, ...rest }) => ({
      ...rest,
      cause: cause !== selected ? selected : "",
    }));
  };

  const handlePage = (action) => {
    if (
      action === "incr" &&
      searchData.page < Math.ceil(filteredGroups.length / 12)
    ) {
      cardsSectionRef?.current?.scrollIntoView({ behavior: "smooth" });

      return setSearchData(({ page, ...rest }) => ({
        ...rest,
        page: page + 1,
      }));
    }
    if (searchData.page === 1) {
      return null;
    }
    cardsSectionRef?.current?.scrollIntoView({ behavior: "smooth" });

    return setSearchData(({ page, ...rest }) => ({ ...rest, page: page - 1 }));
  };

  useEffect(() => {
    setAvailableActivitiesTypesArr(
      [
        { value: "All", label: t("all", language) },
        ...(language === "english"
          ? earnActivityTypesDropDownEnglish
          : earnActivityTypesDropDownWelsh),
      ].map(({ label, value }) => ({
        label: value,
        name: label,
      })),
    );
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const handleChange = (key, value) => {
    setSearchData((_data) => {
      let newSearchData = {
        ..._data,
        [key]: value,
      };

      if (key === "keyword" && _data.sortBy === "relevance" && !value) {
        newSearchData = { ...newSearchData, sortBy: "popularity", page: 1 };
      }

      if (key === "postcode") {
        newSearchData = {
          ...newSearchData,
          location: value ? "" : searchData.location,
          county: value ? "" : searchData.county,
          distance: value ? 50 : 0,
        };
      }

      // if no location then reset sortBy to popularity and page to 1 if sortBy is nearest
      if (
        key === "postcode" &&
        !newSearchData.location &&
        !newSearchData.postcode &&
        !newSearchData?.distance &&
        !newSearchData?.county &&
        newSearchData.sortBy === "nearest"
      ) {
        newSearchData = {
          ...newSearchData,
          sortBy: "popularity",
          page: 1,
        };
      }

      return newSearchData;
    });
  };

  const decideSelectedCause = () => {
    if (!searchData.cause) return undefined;
    if (searchData.cause === "All") {
      return { value: "All", label: t("all", language) };
    }
    if (language === "english") {
      return {
        value: searchData.cause,
        label: searchData.cause,
      };
    }
    return {
      value: searchData.cause,
      label: focusValuesMapWelsh[searchData.cause],
    };
  };

  const handleLocation = (selected = "", section) => {
    setSearchData((_data) => ({
      ..._data,
      county: section === "county" ? selected : "",
      location: section === "location" ? selected : "",
      postcode: selected ? "" : searchData.postcode,
      distance: selected ? _data.distance || 50 : 0,
      sortBy:
        _data.sortBy === "nearest" && !selected ? "popularity" : _data.sortBy,
      page: _data.sortBy === "nearest" && !selected ? 1 : _data.page,
    }));
  };

  useEffect(() => {
    handleFilter({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData.tab]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      const stringifyQuery = generateQuery(searchData, searchParams);
      window.history.replaceState(
        searchData,
        "earn",
        GENERAL.EARN.replace(":search?", stringifyQuery),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchData.location,
    searchData.keyword,
    searchData.activity,
    searchData.cause,
    searchData.page,
    searchData.tab,
    searchData.distance,
    searchData.county,
    searchData.postcode,
    searchData.sortBy,
  ]);

  useEffect(() => {
    // this will do the initial search, and then only if tab, language or sortBy changes
    handleSearch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, searchData.sortBy, searchData.tracker]);

  const locationListCities = citiesList
    ?.sort()
    ?.filter((e) => !!e)
    ?.map((e) => ({
      value: e,
      label: e,
      section: "location",
    }));

  const locationListCounties = countiesList
    ?.sort()
    ?.filter((e) => !!e)
    ?.map((e) => ({
      value: e?.trim(),
      label: e?.trim(),
      section: "county",
    }));

  const isFiltersApplied =
    searchData.postcode ||
    searchData.location ||
    searchData.county ||
    searchData.keyword ||
    Number(searchData.distance) ||
    searchData.activity ||
    searchData.cause;

  const isLocationApplied =
    searchData.postcode || searchData.location || searchData.county;

  return (
    <S.Wrapper onSubmit={handleSearchForm}>
      {isMobile ? (
        <Row mb={5}>
          <Col w={[4, 12, 6]}>
            <S.EarnHeader>
              <T.H30Caps color="midnight">
                {t("findWaysToGive", language)}
              </T.H30Caps>
            </S.EarnHeader>
          </Col>
        </Row>
      ) : (
        <Row mb={9}>
          <Col w={[4, 12, 6]}>
            <S.EarnHeader>
              <T.H40Caps color="midnight">
                {t("findWaysToGive", language)}
              </T.H40Caps>
            </S.EarnHeader>
          </Col>
        </Row>
      )}
      <S.Content>
        <Row mb={5}>
          <Col w={[4, 6, 4]} mb={4}>
            <InputField
              name="postcode"
              placeholder={`${t("typePostcode", language)}...`}
              label={t("searchByPostcode", language)}
              value={searchData.postcode}
              setValue={(e) => handleChange("postcode", e.target.value)}
              clearFunc={() => handleChange("postcode", "")}
            />
          </Col>

          <Col w={[4, 6, 4]} mb={4}>
            <Dropdown
              name="city"
              options={locationListCities}
              value={
                searchData?.location && {
                  label: searchData.location,
                  value: searchData.location,
                }
              }
              setValue={({ selected }) => {
                handleLocation(selected?.value || "", "location");
              }}
              placeholder={`${t("select", language)}...`}
              label={t("searchByCityTown", language)}
              canCreate
              createLabel={t("searchIn", language)}
              isClearable
            />
          </Col>
          <Col w={[4, 6, 4]} mb={4}>
            <Dropdown
              name="county"
              options={locationListCounties}
              value={
                searchData?.county && {
                  label: searchData.county,
                  value: searchData.county,
                }
              }
              setValue={({ selected }) => {
                handleLocation(selected?.value || "", "county");
              }}
              placeholder={`${t("select", language)}...`}
              label={t("searchByCounty", language)}
              canCreate
              createLabel={t("searchIn", language)}
              isClearable
            />
          </Col>
          <Col w={[4, 6, 4]} mb={4}>
            <InputField
              name="distance"
              placeholder={`${t("searchWithinArea", language)}...`}
              label={t("searchWithinArea", language)}
              value={searchData.distance}
              setValue={(e) => handleChange("distance", e.target.value)}
              clearFunc={() => handleChange("distance", "")}
              type="number"
              disabled={
                !searchData.location &&
                !searchData.county &&
                !searchData.postcode
              }
            />
          </Col>
          <Col w={[4, 6, 4]}>
            <InputField
              name="keyword"
              placeholder={`${t("typeHere", language)}...`}
              label={t("searchByKeyword", language)}
              value={searchData.keyword}
              setValue={(e) => handleChange("keyword", e.target.value)}
              clearFunc={() => handleChange("keyword", "")}
            />
          </Col>
        </Row>
        <Row mb={3}>
          <Col w={[4, 8, 8]} style={{ paddingLeft: "25px" }}>
            <TabsSmall
              tabs={topTabs}
              handleClick={handleTab}
              selected={searchData.tab}
            />
          </Col>
        </Row>
        <Row mb={5}>
          {searchData.tab === "searchByActivityType" ? (
            <Col w={[4, 4, 4]}>
              <AutoComplete
                multiple
                fullWidth
                options={availableActivitiesTypesArr}
                filterSelectedOptions
                getOptionLabel={(option) => option.label}
                setValue={handleActivity}
                value={searchData.activity}
                searchByActivity
                placeholder={t("activityType", language)}
                label={t("selectActivityType", language)}
              />
            </Col>
          ) : (
            <Col w={[12, 12, 12]}>
              {isMobile ? (
                <Dropdown
                  name="cause"
                  options={[
                    { value: "All", label: t("all", language) },
                    ...(language === "english"
                      ? focusDropDownEnglish
                      : focusDropDownWelsh),
                  ]}
                  value={decideSelectedCause()}
                  setValue={({ selected }) => handleCause(selected.value)}
                  placeholder={t("selectCause", language)}
                />
              ) : (
                <S.Options>
                  {(language === "english"
                    ? focusDropDownEnglish
                    : focusDropDownWelsh
                  ).map((type) => (
                    <ClickableTag
                      tagName={type.label}
                      handleClick={() => handleCause(type.value)}
                      selected={searchData.cause === type.value}
                      m="0 5px 5px 0"
                      colors={{
                        selectedText: "pink",
                        selectedBackground: "white",
                        selectedBorder: "pink",
                      }}
                    />
                  ))}
                </S.Options>
              )}
            </Col>
          )}
        </Row>
        <Row mb={7}>
          <Col w={[4, 6, 4]}>
            <Button primary size="l" type="submit">
              {t("search", language)}
            </Button>
          </Col>
          {isFiltersApplied && (
            <Col w={[4, 6, 4]}>
              <Button
                secondary
                bg="midnight"
                size="l"
                handleClick={() => {
                  setSearchData((_searchData) => ({
                    ..._searchData,
                    postcode: initialState.postcode,
                    location: initialState.location,
                    keyword: initialState.keyword,
                    distance: initialState.distance,
                    activity: initialState.activity,
                    cause: initialState.cause,
                    sortBy: initialState.sortBy,
                    page: 1,
                    tracker: _searchData.tracker + 1,
                  }));
                }}
              >
                {t("clearFilters", language)}
              </Button>
            </Col>
          )}
        </Row>
        {/* empty div, to use for scroll to */}
        <div ref={cardsSectionRef} />
        <Row mb={4}>
          <Col w={[4, 12, 12]}>
            <S.SortByWrapper>
              <T.Body14B color="midnight" m="0">
                Sort by:{" "}
              </T.Body14B>
              <Divider vertical h="30px" w="1px" bgColor="gray3" m="0 16px" />
              <TabsSmall
                tabs={sortByTabs}
                handleClick={handleSortTab}
                selected={searchData.sortBy}
                tabLineWidth="auto"
                tabSpace="30px"
                disabledTabs={[
                  isLocationApplied || userPostcode ? "" : "nearest", // if no location selected and no postcode then disable nearest
                  searchData?.keyword ? "" : "relevance", // if no keyword then disable relevance
                ]}
                smallFont
              />
            </S.SortByWrapper>
          </Col>
        </Row>
        <Row>
          {filtering || loading ? (
            <Col w={[4, 12, 12]}>
              <Loading center />
            </Col>
          ) : (
            <>
              {filteredGroups.length > 0 ? (
                filteredGroups
                  .slice((searchData.page - 1) * 12, searchData.page * 12)
                  .map((group) => (
                    <Col w={[4, 6, 4]}>
                      <EarnSpendOpportunity
                        path={GENERAL.EARN_GROUP.replace(":id", group.id)}
                        imgUrl={group.imageURL}
                        title={group.name}
                        type="earn"
                        cities={group.city}
                        isOnline={group.isOnline}
                      />
                    </Col>
                  ))
              ) : (
                <Col w={[4, 6, 4]}>
                  <S.NoData>
                    <T.Body16B
                      color="midnight"
                      m="0"
                      style={{ paddingLeft: "15px" }}
                    >
                      {t("noActivitiesFound", language)}
                    </T.Body16B>
                  </S.NoData>
                </Col>
              )}
            </>
          )}
        </Row>
        <Row jc="space-between">
          <Col
            w={[4, 12, 12]}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {searchData.page > 1 && (
              <S.PageButton
                prev
                onClick={() => handlePage("decr")}
                type="button"
              >
                <T.LinkB16 color="blue">{t("back", language)}</T.LinkB16>
              </S.PageButton>
            )}
            {searchData.page < Math.ceil(filteredGroups.length / 12) && (
              <S.PageButton onClick={() => handlePage("incr")} type="button">
                <T.LinkB16 color="blue">{t("next", language)}</T.LinkB16>
              </S.PageButton>
            )}
          </Col>
        </Row>
      </S.Content>
    </S.Wrapper>
  );
};

const SearchPramsProvider = (props) => {
  const { search } = useParams();
  const searchParams = new URLSearchParams(search);
  const searchQuery = getQuery(searchParams);

  const data = { ...initialState, ...searchQuery };

  return <Earn {...props} initialState={data} searchParams={searchParams} />;
};
const mapStateToProps = (state) => ({
  earnGroups: state.earnGroups.data,
  citiesList: state.earnGroups.citiesList,
  countiesList: state.earnGroups.countiesList,
  homonymousStatesList: getListsIntersectionValues(
    state.earnGroups.citiesList,
    state.earnGroups.countiesList,
  ),
  loading: state.earnGroups.getEarnGroupsLoading,
  language: state.auth.decideLanguage(state.auth),
  userPostcode: state.auth?.profile?.postcode,
});

const mapActionToProps = {
  getEarnGroupsAction: getEarnGroups,
  getLocationAction: getLocation,
};

export default connect(mapStateToProps, mapActionToProps)(SearchPramsProvider);
